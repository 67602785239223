@media (max-width: 600px){
    .videoSuggestionModalContainer {
        height: 50vw;
        width: 80vw;
        margin: auto;
        margin-top: 0px;
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        border-radius: 30px;
    }
    .videoSuggestionModalContainer:focus {
        outline:0!important;
    }
    .videoSuggestionBackgroundImage {
        background-image: url("../../assets/exercise/pauseBanner.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: 50vw;
        width: 80vw;
        border-radius: 30px;
        text-align: center;
    }
    .videoSuggestionTopicText {
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 18px;
        /*position:absolute;*/
        /*top:-10px;*/
        margin-left: 0.6vw;
        margin-bottom: 1.5vw;
    }



    .learningVideoTopicText {
        font-family: 'Poppins';
        font-weight: 800;
        font-size: 18px;
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
    }

    .learningVideoPlayerTopic {
        font-family: 'Poppins';
        font-weight: 800;
        font-size: 18px;
    }
}


@media (min-width: 600px){
    .videoSuggestionModalContainer {
        height: 40vw;
        width: 80vw;
        margin: auto;
        margin-top: 0px;
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        border-radius: 30px;
    }
    .videoSuggestionModalContainer:focus {
        outline:0!important;
    }
    .videoSuggestionBackgroundImage {
        background-image: url("../../assets/exercise/pauseBanner.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: 40vw;
        width: 80vw;
        border-radius: 30px;
        text-align: center;
    }
    .videoSuggestionTopicText {
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 2vw;
        margin-left: 0.6vw;
        margin-bottom: 1.5vw;
    }


    .learningVideoTopicText {
        font-family: 'Poppins';
        font-weight: 800;
        font-size: 1.2vw;
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
    }
    .learningVideoPlayerTopic {
        font-family: 'Poppins';
        font-weight: 800;
        font-size: 1.5vw;
    }

}
.learningVideoTopicContainer {
    width: 18vw;
    border-radius: 10000px;
    text-align: center;
    display: table;
    height: 4vw;
    margin-left: 3vw;
    margin-top: 2vw;
    margin-bottom: -6vw;
}

.learningVideoTopicContainerInner {
    display: table-cell;
    vertical-align: middle;
}



.learningVideoSlide {
    width: 83vw;
    margin-left: 2vw;
}

.learningVideoImage {
    width: 26.5vw;
    height: 15vw;
    margin-top: 8vw;
    margin-left: 0.8vw;
    cursor: pointer;
}

.learningVideoName {
    font-family: 'Poppins';
    text-align: center;
}



.learningVideoPlayerReturnDisplayInlineBlock {
    display: inline-block;
}

.learningVideoEmbed {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.learningVideoEmbed iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.learningVideoPlayerContainer {
    width: calc(100% + 4.8vw);
    margin-top: 1vw;
    margin-bottom: 3vw;
}





.speakerImage {
    width: 70%;
    margin-top: 1vw;
    margin-left: 2vw;
}


.messageBoxImage {
    width: 80%;
    margin-bottom: -1vw;
    position: relative;
}

.birdImage {
    width: 80%;
}

.message {
    font-family: 'Poppins';
    font-weight: 500;
    position: absolute;
    font-size: 1.19vw;
    margin-top: -10.3vw;
    margin-left: 3.53vw;
    margin-right: 3.53vw;
}

.videoSuggestionReturnToHomeDisplayInlineBlock {
    display: inline-block;
    margin-left: 20vw;
}

.learningVideoBottomMargin {
    height: 40px;
}

.videoSuggestionModalClose {
    position: absolute;
    height: 4vw;
    right: 0;
    margin-top: 2vw;
    margin-right: 2vw;
    cursor: pointer;
}
