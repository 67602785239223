@media (max-width: 600px){
    .resultTitleContainer{
        margin-top: 40px;
        border-radius: 1000px;
        display: inline-block;
        padding-left: 10px;
        padding-right: 10px;
        height: 16px;
        /*margin-bottom: 1vw;*/
    }

    .resultTitle{
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 18px;
        margin-top: -2vw;
    }


    .weekUpperText1{
        font-family: 'Poppins';
        font-weight: 800;
        font-size: 18px;
        margin-top: -0.7vw;
        margin-bottom: 0px;
    }
    .weekUpperText2{
        font-family: 'Poppins';
        font-weight: 800;
        font-size: 1.53vw;
        margin-top: 0.56vw;
        margin-bottom: 0px;
    }
    .weekAndDayLowerText{
        font-family: 'Poppins';
        font-weight: 800;
        font-size: 18px;
        margin-top: -1.4vw;
        margin-bottom: 0px;
    }
    .weekUpperText1ZH-HK{
        font-family: 'Poppins';
        font-weight: 800;
        font-size: 18px;
        margin-top: -0.7vw;
        margin-bottom: 0px;
    }


    .performanceLevelStudentIconName{
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        margin-left: -100%;
        margin-right: -100%;
        margin-top: 0px;
        margin-bottom: 5px;
    }

    .practiceNowText{
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 18px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .performanceTrendContainer{

        /*width: calc( 100% + 20px);*/
        /*display: inline-block;*/
    }

    .performanceLevelContainer{
        /*width: calc( 100% - 60px);*/
        border-radius: 10px;
        background-color:#F5F5F7;
        margin-top: 10px;
        /*margin-bottom: 7px;*/
        /*margin-Left: 30px;*/
        /*padding-left: 20px;*/
        /*padding-right: 20px;*/
        /*padding-bottom: 15px;*/
    }
}

@media (min-width: 600px){
    .resultTitleContainer{
        margin-top: 40px;
        border-radius: 1000px;
        display: inline-block;
        padding-left: 10px;
        padding-right: 10px;
        height: 1.4vw;
        margin-bottom: 1vw;
    }

    .resultTitle{
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 1.4vw;
        margin-top: -1vw;
    }

    .weekUpperText1{
        font-family: 'Poppins';
        font-weight: 800;
        font-size: 2.65vw;
        margin-top: -0.7vw;
        margin-bottom: 0px;
    }
    .weekUpperText2{
        font-family: 'Poppins';
        font-weight: 800;
        font-size: 1.53vw;
        margin-top: 0.56vw;
        margin-bottom: 0px;
    }
    .weekAndDayLowerText{
        font-family: 'Poppins';
        font-weight: 800;
        font-size: 1.53vw;
        margin-top: -1.4vw;
        margin-bottom: 0px;
    }
    .weekUpperText1ZH-HK{
        font-family: 'Poppins';
        font-weight: 800;
        font-size: 2.2vw;
        margin-top: -0.7vw;
        margin-bottom: 0px;
    }

    .performanceLevelStudentIconName{
        font-size: 0.8vw;
        text-align: center;
        margin-left: -100%;
        margin-right: -100%;
        margin-top: 0px;
        margin-bottom: 5px;
    }

    .practiceNowText{
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 0.8vw;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .performanceTrendContainer{
        width: calc( 100% + 20px);
        display: inline-block;
    }

    .performanceLevelContainer{
        width: calc( 100% - 60px);
        border-radius: 10px;
        background-color:#F5F5F7;
        margin-top: 10px;
        margin-bottom: 7px;
        margin-Left: 30px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 15px;
    }
}


.resultLabel{
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 18px;
    text-decoration: underline;
    margin-top: 0px;
    margin-bottom: 7px;
}
.resultTopicBoxText{
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 22px;
    margin-top: 0px;
    margin-bottom: 0px;
    white-space: pre-line;
}
.bestPerformanceTopicBoxContainer{
    width: calc( 100% - 30px);
    border-radius: 10px;
    background-color:#D2FDCB;
    margin-bottom: 7px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 15px;
}
.topicBoxCover{
    width: 60%;
}
.wellDoneBird{
    width: 100%;
}
.weekCover{
    width: 87%;
}
.dayCover{
    width: 100%;
}
.weekContainer{
    width: calc( 100% - 30px);
    border-radius: 10px;
    background-color:#A3DCCF;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 5px;
}
.dayContainer{
    width: calc( 100% - 30px);
    border-radius: 10px;
    background-color:#FAD880;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 6px;
}
.improvementTopicBoxContainer{
    width: calc( 100% - 30px);
    border-radius: 10px;
    background-color:#FFE8E3;
    margin-bottom: 7px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 15px;
}
.practiceNowContainer{
    width: calc( 100% - 30px);
    border-radius: 20px;
    background-color:#FFE351;
    margin-top: 0px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
}

.dayUpperText{
    font-family: 'Poppins';
    font-weight: 800;
    font-size: 2.65vw;
    margin-top: -0.7vw;
    margin-bottom: 0px;
}
.weekUpperTextSection{
    display: inline-flex;
    flex-direction: row;
}





.performanceLevelBar{
    width: 95%;
}
.performanceLevelIcon{
    width: 100%;
}
.performanceLevelStudentIcon{
    width: 100%;
}
.performanceLevelIconSection{
    width: calc( 100% - 40px);
    margin-bottom: -4px;
    margin-left: 20px;
    padding-top: 20px;
}


.performanceTrendButtonLeft{
    display: inline-block;
    width: 10px;
    position: absolute;
    margin-left: calc(1vw + 50px);
    cursor: pointer;
}
.performanceTrendButtonRight{
    display: inline-block;
    width: 10px;
    position: absolute;
    margin-left: -1vw;
    cursor: pointer;
}
.performanceTrendTable{
    display: inline-block;
    width: calc(100% - 20px);
}
.performanceTrendButtonContainer{
    margin-bottom: 24px;
    width: 10px;
    background-color: red;
    display: inline-block;
}
