
.Nunito {
    font-family: 'Nunito', sans-serif;
    font-family: 'Overpass', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-family: 'Inter', sans-serif;
}


/*for excercise carousel */
.center .slick-center img {
    /*color: #e67e22;*/
    opacity: 1;
    transform: scale(1);
}
.center img {
    opacity: 0.8;
    transform: scale(0.95);
    transition: all 300ms ease;
}
/*////////////////////////////*/

@media (min-width: 600px){
    .responsiveContainer {
        margin-left: 100px;
        margin-right: 100px;
    }

    .verticalCenter {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

@media (max-width: 600px){
    .responsiveContainer {
        /*margin-left: 0px;*/
        /*margin-right: 0px;*/
        margin-left: 20px;
        margin-right: 20px;
        /*padding-left: 20px;*/
        /*padding-right: 20px;*/
    }


    body, html {
        height: 100%;
        width: 100%;
        margin: 0;
    }

    .verticalCenter {
        /*margin: 0;*/
        /*position: absolute;*/
        /*top: 50%;*/
        /*-ms-transform: translateY(-50%);*/
        /*transform: translateY(-50%);*/
    }
}




* {
    -ms-overflow-style: none;
}

/*::-webkit-scrollbar {*/
/*    display: none;*/
/*}*/

/*@media only screen and (max-width: 1024px) {*/
/*    .mainContent {*/
/*        display: none;*/
/*    }*/

/*    .notSupportScreenSizeImageContainer {*/
/*        display: inline-block;*/
/*    }*/
/*}*/











/*ignored*/
/*////////////////////////////////////////////////////*/
@media only screen and (min-width: 1px) {

    /*mainContent will default display, no need to set*/

    .notSupportScreenSizeImageContainer {
        display: none;
    }
}

.notSupportScreenSizeImage {
    width: 100vw;
}

.notSupportScreenSizeImageContainer {
    height: 100vh;
    width: 100vw;
}

@media only screen and (min-width: 800px) {

    /*mainContent will default display, no need to set*/



    .notSupportScreenSizeImageContainer1 {
        display: none;
    }
}

@media only screen and (max-width: 800px) {

    /*mainContent will default display, no need to set*/



    .responsiveContainer7 {
        display: none;
    }
}



.notSupportScreenSizeImageContainer1 {
    height: 100vh;
    width: 100vw;
}
