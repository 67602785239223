.dashboardBanner {
    background-image: url("../../assets/dashboard/dashboardBanner.svg");
    background-repeat: no-repeat;
    background-size: cover;
    /*width: 100%;*/
    /*margin-top: 20px;*/
    border-radius: 30px;
}

.dashboardBanner2 {
    /*height:30vh;*/
    /*margin: 10px;*/
    /*padding: 10px;*/
    background: #FFD38A;
    border-radius: 20px;

    max-height: 40vh;


    /*overflow: auto;*/


    /*flex-grow: 1;*/

    /*height:calc((100vh - 40px)*3/10);*/


}

.dashboardBanner3 {
    /*height: 60vh;*/
    /*background: #FFF1DC;*/


    /*border-radius: 20px;*/

    margin-top: 10px;
    background: #FBB644;
    /*background: #000000;*/
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px 20px 0px 0px;
    /*height:calc((100vh - 40px)*7/10);*/

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
}

.dashboardBanner4 {
    overflow: auto;
    background: rgba(148, 224, 218, 0.52);
    border-radius: 20px;
    /*height: 50vh;*/

    flex-grow: 1;
    flex-basis: 0;
}

/* width */
.dashboardBanner4::-webkit-scrollbar {
    width: 10px;
    background:  white;
    border-radius: 10px;
}

/* Track */
.dashboardBanner4::-webkit-scrollbar-track {
    /*box-shadow: inset 0 0 5px grey;*/
    border-radius: 10px;
}

/* Handle */
.dashboardBanner4::-webkit-scrollbar-thumb {
    background:  #79A49A;

    border-radius: 50px;
}







/*.dashboardBanner4::-webkit-scrollbar {*/
/*  width: 10px;*/
/*  height: 10px;*/
/*}*/

/*.dashboardBanner4::-webkit-scrollbar-thumb {*/
/*  background-color: #ccc;*/
/*  border-radius: 5px;*/
/*}*/
.dashboardBanner5 {
    overflow: auto;

    background: #FFD5C4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    /*height: 50vh;*/


    flex-grow: 1;
    flex-basis: 0;
}


/* width */
.dashboardBanner5::-webkit-scrollbar {
    width: 10px;
    background:  white;
    border-radius: 10px;
}

/* Track */
.dashboardBanner5::-webkit-scrollbar-track {
    /*box-shadow: inset 0 0 5px grey;*/
    border-radius: 10px;
}

/* Handle */
.dashboardBanner5::-webkit-scrollbar-thumb {
    background:  #F99B2C;

    border-radius: 50px;
}







.responsiveContainer8 {
    margin-left: 100px;
    margin-right: 100px;

    /*border: #aef4f4 4px solid;*/
    /*overflow: hidden;*/
    height: calc(100vh - 40px);


    display: flex;
    flex-direction: row;
}

.responsiveContainer9 {
    display: flex;
    flex-direction: column;

    flex-basis: 0;
    flex-grow: 2;

    /*height: calc(100vh - 80px);*/
    /*height: calc(100vh);*/
    overflow: hidden;


    /*border: #23FF00 10px solid;*/
}

.responsiveContainer95 {
    display: flex;
    flex-direction: column;


    /*height: calc(100vh - 80px);*/
    /*height: calc(100vh);*/
    /*overflow: hidden;*/


    border: #23FF00 10px solid;
}


/*.responsiveContainer7{*/

/*    !*height: 100vh;*!*/
/*    !*overflow: auto;*!*/
/*}*/





.res1 {
    border: #D63737 4px solid;
    display: flex;
    flex-direction: column;


    /*height: 10vh;*/
    height: calc(30vh);


    /*width:40vw;*/
    overflow: hidden;
}

.res2 {
    /*height: 100vh;*/
    /*overflow: auto;*/
}

.res3 {
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    border: #23FF00 4px solid;
    /*height: 100vh;*/
    /*overflow: auto;*/
}

.res4 {
    /*flex-shrink: 0;*/
    flex-grow: 1;
    flex-basis: 0;
    border: #aef4f4 4px solid;
    /*height: 100vh;*/
    /*overflow: auto;*/
}

.res5 {
    /*height: 100%;*/
    flex-basis: 0;
    /*width: 100%;*/
    border: #bc28bf 100px solid;
    flex-grow: 1;
    /*height: 100%;*/
    /*height: 100vh;*/
    overflow: auto;
}


.messageRow {
    display: flex;
    width: 80%;
}

.messageRowRight {

    /*border: #bf2828 40px solid;*/
    display: flex;
    justify-content: flex-end;
}

.messageRowRight1 {
    /*border: #23FF00 4px solid;*/
    display: flex;
    flex-direction: column;



    align-items: flex-end;


    /*justify-content: flex-end;*/


    width: 40%;

}

.messageBlue {
    position: relative;
    margin-left: 20px;
    margin-bottom: 10px;
    padding: 10px;


    /*background-color: #A8DDFD;*/
    background: #F4CDA0;


    /*height: 50px;*/
    text-align: left;


    /*font: 400 .9em 'Open Sans', sans-serif;*/
    /*border: 1px solid #97C6E3;*/
    border-radius: 10px;
}

.messageBlue:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;

    border-top: 15px solid #F4CDA0;


    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    top: 0;
    left: -15px;
}

.messageBlue:before {
    /*content: '';*/
    /*position: absolute;*/
    /*width: 0;*/
    /*height: 0;*/
    /*border-top: 17px solid #97C6E3;*/
    /*border-left: 16px solid transparent;*/
    /*border-right: 16px solid transparent;*/
    /*top: -1px;*/
    /*left: -17px;*/
}

.messageOrange {

/*    border: #4bc4ef 4px solid*/
/*;*/
    position: relative;
    margin-right: 20px;
    margin-bottom: 10px;
    padding: 10px;

    /*background-color: #f8e896;*/
    background: rgba(194, 226, 228, 0.84);

    /*width: 60%;*/
    /*/height: 50px;/*/
    text-align: left;
    /*font: 400 .9em 'Open Sans', sans-serif;*/
    /*border: 1px solid #dfd087;*/
    border-radius: 10px;
}

.messageOrange:after {
    /*content: '';*/
    /*position: absolute;*/
    /*width: 0;*/
    /*height: 0;*/
    /*border-top: 15px solid #f8e896;*/

    /*border-left: 15px solid transparent;*/
    /*border-right: 15px solid transparent;*/
    /*top: 0;*/
    /*right: -15px;*/
}

.messageOrange:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 17px solid rgba(194, 226, 228, 0.84);
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    top: -1px;
    right: -17px;
}

.messageContent {
    padding: 0;
    margin: 0;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /*line-height: 115.69%;*/
    /* identical to box height, or 43px */

    /*letter-spacing: -0.005em;*/

    color: #30230F;
}

.messageTimeStampRight {
    position: absolute;
    font-size: .85em;
    font-weight: 300;
    margin-top: 10px;
    bottom: -3px;
    right: 5px;
}

/*.orange {*/
/*    color: ${theme.palette.getContrastText(deepOrange[500])};*/
/*    background-color: ${deepOrange[500]};*/
/*    width: ${theme.spacing(4)};*/
/*    height: ${theme.spacing(4)};*/
/*}*/

/*.avatarNothing {*/
/*    color: transparent;*/
/*    background-color: transparent;*/
/*    width: ${theme.spacing(4)};*/
/*    height: ${theme.spacing(4)};*/
/*}*/

.displayName {
    /*margin-left: 20px;*/


    /*border: #eeeeee 14px solid;*/

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    /*font-size: 25.0562px;*/
    line-height: 38px;
    /* identical to box height */

    /*letter-spacing: 0.05em;*/

    color: #67676C;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.displayName1 {
    margin-left: 20px;


    /*border: #eeeeee 14px solid;*/

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    /*font-size: 25.0562px;*/
    line-height: 38px;
    /* identical to box height */

    /*letter-spacing: 0.05em;*/

    color: #67676C;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.dashboardMargin2 {
    background: #FFF0CF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;;

    margin: 20px;
}
.dashboardMargin2-1{
    /*border: #23FF00 4px solid;*/
    display: flex;
    /*flex-direction: column;*/
    flex-direction: row;

    align-items: center;
    justify-content: center; /* Optional, to center horizontally as well */
    /*background: #FFF0CF;*/
    /*box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);*/
    /*border-radius: 20px;;*/

    /*margin: 20px;*/

    margin-left: auto;
}

.dashboardMargin {
    height: 2vw;
}

.dashboardMargin05 {

    padding: 20px;
}

.dashboardMargin1 {

    /*padding: 15px;*/
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    /* identical to box height, or 125% */


    color: #53100C;
}

.dashboardMargin1-1 {

    display: flex;
    flex-direction: row;


}

.dashboardMargin1-2 {

    display: flex;
    flex-direction: row;
overflow: auto;

}

.dashboardMargin1-1::-webkit-scrollbar {
    display: none;
}

.dashboardMargin3 {

    padding: 10px;
    margin: 10px;
    /*border: 1px solid #ccc;*/

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    /*font-size: 24px;*/
    /*line-height: 40px;*/
    /* or 167% */


    /* Black */

    color: #000000;
}

.dashboardMargin4 {


    text-align: center;


    padding: 10px;


    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    /* or 125% */


    color: #174F49;
}

.dashboardMargin5 {

    text-align: center;
    padding: 10px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    /* or 125% */


    color: #5A3223;
}

.dashboardMargin6 {
    margin: 20px;
    padding: 30px;


    background: #EEFFFD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    display: flex;
    align-items: center;
}

.dashboardMargin7 {
    /*margin: 20px;*/

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    /*line-height: 40px;*/
    /* identical to box height, or 167% */
    /*border: #23FF00 solid 2px;*/

    color: #1F1919;
}


.dashboardMargin75 {
    /*margin: 20px;*/
    /*border: #23FF00 solid 2px;*/
    margin-left: auto;

}

.dashboardMargin9 {
    margin: 20px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
    /* or 200% */


    color: #000000;


}

.dashboardMargin91 {
    display: flex;
    flex-direction: row;
    align-items: center;
    /*border: #23FF00 solid 2px;*/

}

.dashboardMargin92 {
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    /*border: #23FF00 solid 2px;*/

}

.dashboardMargin8 {
    margin: 20px;
    padding: 6px;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    background: #FFFFFF;
    border-radius: 10px;
}

.dashboardMargin10 {
    /*margin: 20px;*/
    /*padding: 6px;*/
    /*height: 100%;*/


    /*background: #FBB644;*/
    /*!*background: #000000;*!*/
    /*box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);*/
    /*border-radius: 20px 20px 0px 0px;*/
}

.dashboardMargin11 {
    /*margin: 20px;*/
    padding: 10px;


    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    /* or 125% */
    color: #774434;


    /*height:calc((100vh - 40px)*7/10*1/10);*/
}

.dashboardMargin12 {
    /*margin: 20px;*/
    /*padding: 6px;*/
    /*border: #3a5aff 10px solid;*/
    background: #FFF1DC;


    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    /*align-items: center;*/

    /*height:calc((100vh - 40px)*7/10*9/10);*/

}

.dashboardMargin121 {
    /*margin: 20px;*/
    /*padding: 6px;*/

    /*border: #3a5aff 10px solid;*/
    /*background: #FFF1DC;*/
    margin-left: 10px;

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    overflow: hidden;
    /*align-items: center;*/

    /*height:calc((100vh - 40px)*7/10*9/10);*/

}

.dashboardMargin125 {
    /*border: #5a3223 10px solid;*/


    justify-content: center;
    flex-wrap: wrap;

    flex-grow: 12;
    /*margin: 20px;*/
    /*height:60vh;*/

    display: flex;
    flex-direction: row;
    overflow: hidden;
    /*padding: 50px;*/

}

.dashboardMargin13 {
    /*border: #a3dccf 10px solid;*/


    flex-basis: 0;

    padding: 30px;
    /*height: 100%;*/
    /*padding: 50px;*/
    flex-grow: 0.3;
    /*height: 100%;*/
    /*display: flex;*/
    /*flex-direction: column;*/


    /*margin: 20px;*/
    /*padding: 6px;*/

}

.dashboardMargin134 {
    /*border: #a3dccf 10px solid;*/

    height: 100%;
    /*!*flex-grow: 1;*!*/
    background-image: url("../../assets/Rectangle 398.png");
    background-repeat: no-repeat;
    /*background-position: center;*/
    /*background-size: 80%;*/
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    /*position: absolute;*/
    /*top: 0;*/
    /*left: 0;*/
    /*width: 100%;*/
    /*height: 50%;*/
    /*background-image: url("../../assets/Rectangle 398.png");*/

    /*background-size: 50% ;*/
    /*background-repeat: no-repeat;*/
    /*background-position: center bottom;*/


}

.dashboardMargin135 {
    /*margin-top: 3px;*/
    /*height: 40vh;*/


    /*border: #23FF00 4px solid;*/

    /*height: 100%;*/
    /*width: 100%;*/
    /*width: 100%;*/
    /*flex-grow: 1;*/
    /*height: 100%; !* Set the height of the image to 100% of its container *!*/
    /*width: auto; !* Ensure the image's aspect ratio is maintained *!*/
    /*display: block; !* R*/
    /*max-height:100%;*/
    /*max-width:100%;*/
    /*    height:auto;*/
    /*    object-fit: contain;*/


    /*display: flex;*/
    /*flex-flow: row nowrap;*/
    /*align-items: center;*/
    /*max-width: 30px;*/
    /*width: 100%;*/
    /*object-fit: cover;*/
}

.dashboardMargin136 {
    /*margin-top: 3px;*/
    /*height: 50VH;*/
    /*width: 60px;*/
    /*margin: auto;*/
    /*height: 80px;*/


    /*position: relative;*/
    /*top: 50%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
}


.dashboardMargin14 {
    /*margin: 20px;*/
    padding: 30px;
}

.dashboardMargin145 {
    /*margin: 20px;*/
    /*padding: 30px;*/
    /*border: #D63737 17px solid;*/
    flex-grow: 1;


    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.dashboardMargin146 {
    /*margin: 20px;*/
    /*padding: 30px;*/
    /*border: #D63737 17px solid;*/

    flex-basis: 0;

    flex-grow: 9;


}.dashboardMargin146-0 {
     /*margin: 20px;*/
     /*padding: 30px;*/
     /*border: #D63737 17px solid;*/

     flex-basis: 0;
     flex-grow: 1;


 }

.dashboardMargin15 {
    /*margin: 20px;*/
    /*padding: 6px;*/
    position: relative;
    /*top: 30%;*/
    height: 50px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}

.dashboardMargin16 {
    /*border: #23FF00 4px solid;*/

    flex-basis: 0;
    flex-grow: 1;

    display: flex;
    /*margin: 20px;*/
    /*padding: 6px;*/
    /*border: #4ABF8E 8px solid;*/
    /*  border-radius: 20px;*/
    /*height: 50vh*/
}

.dashboardMargin171 {
    display: flex;
    align-items: center;
    justify-content: center; /* Optional, to center horizontally as well */
    /*margin: 20px;*/
    /*padding: 6px;*/
    /* width: 30px;*/

    /*height: 4rem;*/

}

.dashboardMargin17 {
    /*margin: 20px;*/
    /*padding: 6px;*/
    /* width: 30px;*/

    /*height: 4rem;*/

}

.dashboardMargin18 {
    /*margin: 20px;*/
    /*padding: 6px;*/
    /*border: #4ABF8E 20px solid;*/
    /*border-radius: 20px;*/
    /*height: 50vh*/
}

.dashboardMargin189 {
    /*margin: 20px;*/
    /*padding: 6px;*/
    /*border: #3a5aff 10px solid;*/
    background: #FFF1DC;

    flex-basis: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    /*align-items: center;*/

    /*height:calc((100vh - 40px)*7/10*9/10);*/

}

.dashboardMargin19 {
    padding: 30px;

    /*height:40vh;*/
    /*flex-grow: 3;*/

    /*height: 10vh;*/


    /*height: 100%;*/


    flex-basis: 0;
    flex-grow: 1;
    overflow: auto;
    /*border: #5a3223 40px solid;*/
    /*height:100%;*/

    /*min-height:0;*/


    /*margin: 20px;*/
    /*padding: 6px;*/
    /*border: #4ABF8E 20px solid;*/
    /*border-radius: 20px;*/
    /*height: 50vh*/
}

/* width */
.dashboardMargin19::-webkit-scrollbar {
    width: 10px;
    background:  white;
    border-radius: 10px;
}

/* Track */
.dashboardMargin19::-webkit-scrollbar-track {
    /*box-shadow: inset 0 0 5px grey;*/
    border-radius: 10px;
}

/* Handle */
.dashboardMargin19::-webkit-scrollbar-thumb {
    background:  #F99B2C;

    border-radius: 50px;
}





.searchTextField1 {
    background-color: #F9F9F9;
    border-radius: 100px;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.sssssssssssssss {

    /*// these two needed as carousell sucks*/
    /*padding-left:16px;*/
    /*padding-top:30px*/
}

@media (max-width: 600px) {

    .dashboardBannerHelloText {
        font-family: 'Inter';
        font-weight: 700;
        font-size: 22px;
        color: #024AAD;
        margin: 12px 12px 0px 12px;
        /*padding:3px;*/
        /*margin-top: 0px;*/
        /*margin-bottom: 0;*/
        /*margin-left: 30px;*/
    }

    .dashboardBannerThemeText {
        font-family: 'Inter';
        font-weight: 700;
        font-size: 22px;
        color: #024AAD;
        margin: 0px 12px 12px 12px;
        /*padding:3px;*/
        /*margin-top: 3px;*/
        /*margin-bottom: 0;*/
        /*margin-left: 30px;*/
    }

    .dashboardBannerTopicText {
        color: #15B1BE;
    }


    .questionOfTheDayContentContainer {
        margin-left: 1vw;
    }

    .questionOfTheDayTitle {
        font-family: 'Inter';
        font-weight: 700;
        color: #4E7D38;
        font-size: 22px;
        /*margin-top: calc(0.4vw + 10px);*/
        /*margin-bottom: 0;*/
        /*border-style: dotted;*/
    }


    .questionOfTheDayText {
        font-family: 'Inter';
        font-weight: 700;
        color: #FF7313;
        font-size: 16px;
        margin-top: -10px;
        /*margin-bottom: ;*/
    }

    .questionOfTheDayQuestion {
        font-family: 'Inter';
        font-weight: 700;
        font-size: 16px;
        margin-top: -10px;
        /*margin-bottom: 1vw;*/
        /* white-space: nowrap; */
        /* overflow: hidden;
        display: block;
        text-overflow: ellipsis; */
    }


    .multipleChoicesContainer {
        width: 100%;
        background-color: white;
        border-radius: 10px;
        cursor: pointer;
        flex-direction: row;
        display: flex;
        justify-content: flex-start;
    }

    .questionOfTheDayModalAnswerSmall {
        font-family: 'Inter';
        font-weight: 700;
        font-size: 16px;
        margin-left: 1.5vw;
        padding-top: 1vw;
        padding-bottom: 1vw;
    }

    .todaysPracticesTitleContainer {
        /*margin-top: 4vw;*/
        background-color: #FFE351;
        border-radius: 1000px;
        display: inline-block;
        /*padding-left: 10px;*/
        /*padding-right: 10px;*/
        height: 3vw;
        margin: 20px 20px 20px 0px;
        /*margin-bottom: 2.4vw;*/

    }

    .todaysPracticesTitle {
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 18px;
        /*margin-top: -15px;*/
        position: relative;
        top: -30px;
    }


    .practiceContainer {
        /*width: calc( 100% - 40px);*/
        border-radius: 10px;
        box-shadow: 1px 1px 10px #ccc;
        margin-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 15px;
    }

    .practiceTitle {
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 18px;
        /*margin-top: 0px;*/
        /*margin-bottom: 0px;*/
    }

    .practiceProgressText {
        /*margin-top: 0px;*/
        /*margin-bottom: 0px;*/

        /*margin:0;*/
        /*margin-bottom: 10px;*/
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 18px;
        text-align: center;


        /*add a border*/

    }

    .practiceProgressContainer {
        /*width: 100%;*/
        border-radius: 10000px;
        border: 0.1vw solid black;
        height: 2.4vw;
    }

    .practiceProgress {
        border-radius: 10000px;
        height: calc(2.4vw - 0.4vw);
        margin-left: 0.3vw;
        margin-top: calc(-2.4vw + 0.1vw);
    }

    .searchSuggestionBottomContainer {

        background-color: #F9F9F9;
        border-bottom-left-radius: 29px;
        border-bottom-right-radius: 29px;
        position: absolute;
        /*width: calc(50% - 110px);*/
        width: calc(100% - 8vw);
        padding-bottom: 10px;
        padding-top: 10px;
        z-index: 1000;
    }

    .searchSuggestionBottomText {
        margin-left: 30px;
        margin-bottom: 0px;
        margin-top: 0px;
        padding-top: 8px;
        padding-bottom: 8px;
        cursor: pointer;
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 16px;
    }


    .searchBtnContainer {
        display: flex;
        justify-content: center;
        /*text-align:center;*/
        background-color: #F6C840;
        height: 60px;
        width: 60px;
        /*min-height: 4vw;*/
        /*min-width: 4vw;*/
        /*max-height: 4.4vw;*/
        /*max-width: 4vw;*/
        margin-right: -12px;
        border-top-right-radius: 100000px;
        border-bottom-right-radius: 100000px;
        cursor: pointer;
    }

    .searchSuggestionBtnContainer {
        /*text-align:center;*/
        display: flex;
        justify-content: center;
        /*min-height: 4vw;*/
        /*min-width: 4vw;*/
        height: 60px;
        width: 60px;
        margin-right: -12px;
        cursor: pointer;
    }

    .searchWhite {
        /*min-width: 2vw;*/
        /*max-width: 2vw;*/
        width: 30px;
        /*margin-left: max(10px, 0.8vw);*/
        /*margin-top: max(15px, 1vw);*/
    }


    .todaysVideoCover {
        z-index: 1;
        margin-left: 2vw;
        margin-top: 1vw;
        width: 100%;
    }

    .todaysVideoArrow {
        position: absolute;
        width: 10%;
        left: 62%;
        top: 38%
        /*margin-left: 12vw;*/
        /*margin-top: 7.5vw;*/
        /*display:block;*/
        /*margin:auto;*/
    }

    .todaysVideoContainer {
        cursor: pointer;
        /*text-align: center;*/
        /*display: flex;*/
        /*align-items: center;*/
        /*justify-content: center;*/
    }

    .todaysQuestionAnswerBackgroundImage {
        background-image: url("../../assets/dashboard/todaysQuestionAnswerBanner.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: 40vw;
        width: 80vw;
        border-radius: 20px;
    }


    .questionOfTheDayModalQuestion {
        font-family: 'Inter';
        font-weight: 700;
        font-size: 14px;
        margin-top: 0vw;
        margin-bottom: 0;
    }

    .questionOfTheDayModalType {
        color: grey;
        font-family: 'Inter';
        font-weight: 700;
        font-size: 10px;
        margin-top: 0vw;
        margin-bottom: 1.5vw;
        text-transform: capitalize;
    }

    .questionOfTheDayModalAnswer {
        font-family: 'Inter';
        font-weight: 700;
        font-size: 12px;
        margin-left: 1.5vw;
        padding-top: 1vw;
        padding-bottom: 1vw;
    }
}


@media (min-width: 600px) {
    .dashboardBannerHelloText {
        font-family: 'Inter';
        font-weight: 700;
        font-size: 1.8vw;
        color: #024AAD;
        margin-top: 0px;
        margin-bottom: 0;
        margin-left: 30px;
    }


    .dashboardBannerThemeText {
        font-family: 'Inter';
        font-weight: 700;
        font-size: 1.8vw;
        color: #024AAD;
        margin-top: 3px;
        margin-bottom: 0;
        margin-left: 30px;
    }

    .dashboardBannerTopicText {
        color: #15B1BE;
    }


    .questionOfTheDayContentContainer {
        margin-left: 1vw;
    }

    .questionOfTheDayTitle {
        font-family: 'Inter';
        font-weight: 700;
        color: #4E7D38;
        font-size: 2vw;
        margin-top: calc(0.4vw + 10px);
        margin-bottom: 0;
    }


    .questionOfTheDayText {
        font-family: 'Inter';
        font-weight: 700;
        color: #FF7313;
        font-size: 1vw;
        margin-top: 0.4vw;
        margin-bottom: 0.2vw;
    }

    .questionOfTheDayQuestion {
        font-family: 'Inter';
        font-weight: 700;
        font-size: 1.1vw;
        margin-top: 0vw;
        margin-bottom: 1vw;
        /* white-space: nowrap; */
        /* overflow: hidden;
        display: block;
        text-overflow: ellipsis; */
    }

    .multipleChoicesContainer {
        width: 100%;
        background-color: white;
        border-radius: 10px;
        cursor: pointer;
        flex-direction: row;
        display: flex;
        justify-content: flex-start;
    }

    .questionOfTheDayModalAnswerSmall {
        font-family: 'Inter';
        font-weight: 700;
        font-size: 1vw;
        margin-left: 1.5vw;
        padding-top: 1vw;
        padding-bottom: 1vw;
    }

    .todaysPracticesTitleContainer {
        margin-top: 4vw;
        background-color: #FFE351;
        border-radius: 1000px;
        display: inline-block;
        padding-left: 10px;
        padding-right: 10px;
        height: 1.4vw;
        margin-bottom: 2.4vw;
    }

    .todaysPracticesTitle {
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 1.4vw;
        margin-top: -1vw;
    }

    .practiceContainer {
        width: calc(100% - 40px);
        border-radius: 10px;
        box-shadow: 1px 1px 10px #ccc;
        margin-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 15px;
    }

    .practiceTitle {
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 1.4vw;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .practiceProgressText {
        margin-top: 0px;
        margin-bottom: 0px;
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 1.2vw;
        text-align: center;
    }

    .practiceProgressContainer {
        width: 100%;
        border-radius: 10000px;
        border: 0.1vw solid black;
        height: 2.4vw;
    }

    .practiceProgress {
        border-radius: 10000px;
        height: calc(2.4vw - 0.4vw);
        margin-left: 0.3vw;
        margin-top: calc(-2.4vw + 0.1vw);
    }


    .searchSuggestionBottomContainer {
        background-color: #F9F9F9;
        border-bottom-left-radius: 29px;
        border-bottom-right-radius: 29px;
        position: absolute;
        width: calc(50% - 110px);
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .searchSuggestionBottomText {
        margin-left: 30px;
        margin-bottom: 0px;
        margin-top: 0px;
        padding-top: 8px;
        padding-bottom: 8px;
        cursor: pointer;
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 16px;
    }

    .searchBtnContainer {
        background-color: #F6C840;
        height: 60px;
        width: 60px;
        min-height: 4vw;
        min-width: 4vw;
        max-height: 4.4vw;
        max-width: 4vw;
        margin-right: -12px;
        border-top-right-radius: 100000px;
        border-bottom-right-radius: 100000px;
        cursor: pointer;
    }

    .searchSuggestionBtnContainer {
        min-height: 4vw;
        min-width: 4vw;
        height: 60px;
        width: 60px;
        margin-right: -12px;
        cursor: pointer;
    }

    .searchWhite {
        min-width: 2vw;
        max-width: 2vw;
        width: 30px;
        margin-left: max(10px, 0.8vw);
        margin-top: max(15px, 1vw);
    }


    .todaysVideoCover {
        z-index: 1;
        margin-left: 2vw;
        margin-top: 2vw;
        width: 100%;
    }

    .todaysVideoArrow {
        position: absolute;
        width: 3vw;
        margin-left: 12vw;
        margin-top: 7.5vw;
    }

    .todaysVideoContainer {
        cursor: pointer;
    }


    .todaysQuestionAnswerBackgroundImage {
        background-image: url("../../assets/dashboard/todaysQuestionAnswerBanner.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: 40vw;
        width: 80vw;
        border-radius: 80px;
    }


    .questionOfTheDayModalQuestion {
        font-family: 'Inter';
        font-weight: 700;
        font-size: 1.3vw;
        margin-top: 0vw;
        margin-bottom: 0;
    }


    .questionOfTheDayModalType {
        color: grey;
        font-family: 'Inter';
        font-weight: 700;
        font-size: 1.1vw;
        margin-top: 0vw;
        margin-bottom: 1.5vw;
        text-transform: capitalize;
    }


    .questionOfTheDayModalAnswer {
        font-family: 'Inter';
        font-weight: 700;
        font-size: 1.2vw;
        margin-left: 1.5vw;
        padding-top: 1vw;
        padding-bottom: 1vw;
    }
}

.questionOfTheDayFrame-3 {
    position: relative;
    z-index: 100;
    margin: 10px;
    top: 25px
    /*padding-top: 30px;*/
    /*padding-bottom: 0px;*/
    /*right: 0px;*/
    /*width: 707px;*/
    /*height: 739px;*/
    /*left: 1140px;*/
    /*top: 145px;*/
}

.questionOfTheDayFrame-25 {
    display: block;
    margin-left: auto;
    /*position: relative;*/
    /*right: 10px;*/
}

.questionOfTheDayFrame-2 {
    position: absolute;
    z-index: -100;
    /*width: 707px;*/
    /*height: 739px;*/
    /*left: 1140px;*/
    /*top: 145px;*/
    right: 10px;
    bottom: 10px;
}

.questionOfTheDayFrame-1 {
    position: absolute;
    z-index: -100;
    /*width: 707px;*/
    /*height: 739px;*/
    /*left: 1140px;*/
    /*top: 145px;*/
}

.questionOfTheDayFrame0 {
    position: relative;
    left: 9vw;

    width: 84%;
    /*  height: 100%;*/
    cursor: pointer;
}

.questionOfTheDayFrame00 {
    position: absolute;
    width: 20vw;
    right: 24vw;
    top: 27vw;
    cursor: pointer;
    z-index: 100;
}

.questionOfTheDayFrame000 {
    /*width: 100%;*/

}

.questionOfTheDayFrame0000 {
    width: 10vw;

}

.questionOfTheDayFrame00000 {
    width: 80%;

}

.questionOfTheDayFrame {
    width: 100%;

}


@import url('https://fonts.googleapis.com/css2?family=Just+Me+Again+Down+Here&display=swap');
.questionOfTheDayFrame2 {
    /*position: absolute;*/
    /*width: 1451px;*/
    /*height: 109px;*/
    /*left: 432px;*/
    /*top: 130px;*/

    font-family: 'Just Me Again Down Here', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 100px;
    /*line-height: 24px;*/
    /* or 11% */

    display: flex;
    align-items: center;
    letter-spacing: -0.011em;

    color: #121B27;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.questionOfTheDayFrame3 {
    /*position: absolute;*/
    /*width: 1451px;*/
    /*height: 109px;*/
    /*left: 432px;*/
    /*top: 130px;*/

    font-family: 'Just Me Again Down Here', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 30px;
    /* identical to box height, or 47% */

    display: flex;
    align-items: center;
    letter-spacing: -0.011em;

    color: #F29C1F;
}


.questionOfTheDayFrame4 {
    /*position: absolute;*/
    /*width: 1451px;*/
    /*height: 109px;*/
    /*left: 432px;*/
    /*top: 130px;*/

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;

    /*line-height: 48px;*/

    /*align-items: center;*/

    color: #000000;
}

.questionOfTheDayFrame44 {
    /*position: absolute;*/
    /*width: 1451px;*/
    /*height: 109px;*/
    /*left: 432px;*/
    /*top: 130px;*/

    /*display: inline-block;*/
}

.questionOfTheDayFrame6 {
    /*position: absolute;*/
    /*width: 1451px;*/
    /*height: 109px;*/
    /*left: 432px;*/
    /*top: 130px;*/
    background-color: #FCE37A;
}

.questionOfTheDayFrame66 {
    /*position: absolute;*/
    /*width: 1451px;*/
    /*height: 109px;*/
    /*left: 432px;*/
    /*top: 130px;*/

    background-color: #FCE37A;
}

.questionOfTheDayFrame5 {
    /*position: absolute;*/
    /*width: 1451px;*/
    /*height: 109px;*/
    /*left: 432px;*/
    /*top: 130px;*/
    margin: 20px;
    padding: 50px;
    background: rgba(255, 238, 219, 0.6);
    border-radius: 10px;
}

.questionOfTheDayFrame7 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;

    margin-bottom: 20px;
    color: #000000;
}

.questionOfTheDayFrame75 {
    /*font-family: 'Poppins';*/
    font-style: normal;
    font-weight: 400;
    font-size: 40px;

    margin-bottom: 20px;
    color: #000000;
}


.questionOfTheDayFrame8 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    /*position: absolute;*/
    /*width: 30%;*/
    /*height: 100px;*/
    /*left: 1068px;*/
    /*top: 212px;*/
    /*padding: 20px;*/
    /*margin: 20px;*/
    margin-bottom: 20px;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}


.questionOfTheDayFrame9 {
    /*font-family: 'Poppins';*/
    /*font-style: normal;*/
    /*font-weight: 400;*/
    /*font-size: 40px;*/
    /*!*position: absolute;*!*/
    /*!*width: 30%;*!*/
    /*!*height: 100px;*!*/
    /*!*left: 1068px;*!*/
    /*!*top: 212px;*!*/
    /*padding: 20px;*/
    margin: 20px;

}


.questionOfTheDayFrame10 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 40px;
    /* identical to box height, or 83% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;
}

.questionOfTheDayFrame11 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    letter-spacing: -0.011em;

    color: #000000;
}

.questionOfTheDayFrame12 {
    /*width: 800px;*/
}

.questionOfTheDayFrame13 {
    display: block;
    /*margin-left: auto;*/
    /*width: 800px;*/
    right: 0;
}

.questionOfTheDayFrame14 {
    margin: 10px;
    /*display: block;*/
    /*!*margin-left: auto;*!*/
    /*!*width: 800px;*!*/
    /*right: 0;*/
    float: right;
}


.questionOfTheDayContainer {
    margin-top: -20vw;
    /*background-image: url("../../assets/dashboard/questionOfTheDayFrame.png");*/
    /*background-repeat: no-repeat;*/
    /*background-size: cover;*/
    /*width: 100%;*/
}

.QualiFlyBird {
    height: 15vw;
    margin-top: 2vw;
    margin-left: -5vw;
}


.questionOfTheDayType {
    color: grey;
    font-family: 'Inter';
    font-weight: 700;
    font-size: 1.1vw;
    margin-top: 0vw;
    margin-bottom: 1vw;
    text-transform: capitalize;
}


.multipleChoicesAnswer {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 1vw;
    margin-left: 1.5vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
}

.checkOutTheAnswerContainer {
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
}

.checkOutTheAnswer {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 0.85vw;
    color: #DCB02F;
    margin-right: 0.5vw;
    margin-top: -2.5vw;
    line-height: 0.9vw;
    cursor: pointer;
    width: 6vw;
    height: 5vw;
}

.checkOutTheAnswerArrow {
    margin-bottom: -0.1vw;
    height: 0.7vw;
}


.practiceCover {
    width: 60%;
}

.practiceStatus {
    width: 8vw;
    text-align: center;
    border-radius: 10000px;
    margin-top: 2.1vw;
    height: 2.4vw;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

.practiceStatusText {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 1vw;
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 2.4vw;
}


.practiceProgressFinishedText {
    color: #50CC17;
}

.practiceProgressFinishWellDoneSVG {
    width: 7vw;
}

.todaysPracticesBottom {
    height: 50px;
}

.todaysQuestionAnswerModalContainer {
    height: 40vw;
    width: 80vw;
    margin: auto;
    margin-top: 0px;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    border-radius: 30px;
}

.todaysQuestionAnswerModalContainer:focus {
    outline: 0 !important;
}

.todaysQuestionAnswerClose {
    height: 4vw;
    float: right;
    margin-top: 2vw;
    margin-right: 2vw;
    cursor: pointer;
}

.todaysQuestionAnswerContainer {
    margin-left: 3vw !important;
    padding-top: 3vw;
}

.margin3vw {
    height: 3vw;
}

.todaysQuestionAnswerCorrect {
    width: 2vw;
    margin-left: auto;
    margin-right: 0.6vw;
}

.todaysQuestionAnswerCorrectSmall {
    width: 1.6vw;
    margin-left: auto;
    margin-right: 0.6vw;
}

.questionOfTheDayQuestionAnswer {
    color: #4ABF8E;
}

.searchBoxText {
    font-family: 'Poppins';
    font-weight: 800;
    font-size: 1.2vw;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
}

.searchBoxFirstContainer {
    cursor: pointer;
    width: 100%;
    background-color: #FCEB92;
    border-radius: 10000px;
    text-align: center;
    display: table;
    height: 60px;
    overflow: hidden;
    min-height: 4vw;
}

.searchBoxSecondContainer {
    cursor: pointer;
    width: 100%;
    background-color: #FECF76;
    border-radius: 10000px;
    text-align: center;
    display: table;
    height: 60px;
    overflow: hidden;
    min-height: 4vw;
}

.searchBoxThirdContainer {
    cursor: pointer;
    width: 100%;
    background-color: #A3DCCF;
    border-radius: 10000px;
    text-align: center;
    display: table;
    height: 60px;
    overflow: hidden;
    min-height: 4vw;
}

.searchBoxFirstContainerInner {
    display: table-cell;
    vertical-align: middle;
}


.searchTextField {
    background-color: #F9F9F9;
    border-radius: 100px;
}

.searchSuggestionTextField::placeholder {
    font-size: 2vw;
}
