
@media (max-width: 600px){
  .loginBackgroundImage{
    background: url("../../assets/login/loginBanner_m.png") no-repeat;
    background-size: cover;
    height: 100vh;
    margin-top: -70px;
  }

  .loginButton_test{
    color: #fff;
    border-radius: 1000px;
  }

  .logoutModalContainer{
    height: 40vw;
    width: 90vw;
    /*box-sizing: border-box;*/
    margin: auto;
    /*margin-top: 0px;*/
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    border-radius: 30px;
  }
  .logoutModalContainer:focus {
    outline:0!important;
  }
  .logoutBackgroundImage{
    background-image: url("../../assets/login/logoutBanner.png");
    background-repeat: no-repeat;
    background-size: cover;
    /*height: 40vw;*/
    width: 90vw;
    border-radius: 30px;
    text-align: center;
  }
  .logoutConfirmText{
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 800;
    color: #024AAD;
    padding-top: 7vw;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .logoutContent{
    width: 50vw;
    margin-top: 2vw;
  }
  .logoutButtonContainer{
    margin-top: 3vw;
    padding-bottom:3vw;
  }
  .logoutDisplayInlineBlock{
    display: inline-block;
  }
  .logoutButtonMargin{
    width: 4vw;
    display: inline-block;
  }



  /*//11////////////////*/
  .contactUsEmailText{
    font-size: 18px;
    font-family: 'Nunito';
    font-weight: 600;
    text-align: left;
    color: #444B59;
    letter-spacing: 0.17vw;
    margin-bottom: 0.55vw;
  }
  .contactUsBackgroundImage{
    background-image: url("../../assets/login/contactUsBanner_m.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    margin-top: -70px; /*shift it upward */

    background-position: center;
  }

  .accountSettingsText{
    margin-top: 60vh;
    font-size: 18px;
    font-family: 'Overpass';
    font-weight: 800;
    color: #444B59;
    text-align: center;
    margin-bottom: 2.08vw;
    letter-spacing: 0.17vw;
  }
  .accountSettingsTypeText{
    font-size: 15px;
    font-family: 'Poppins';
    font-weight: 600;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: #024AAD;
    text-decoration-thickness: 0.17vw;
    text-underline-offset: 0.35vw;
    margin-bottom: 2.43vw;
  }
  .newNameText{
    font-size: 15px;
    font-family: 'Nunito';
    font-weight: 600;
    text-align: left;
    color: #444B59;
    letter-spacing: 0.17vw;
    margin-bottom: 0.55vw;
  }

  .forgotPasswordClose{
    height: 2rem;
    float: right;
    margin-top: 1vw;
    margin-right: 1vw;
    cursor: pointer;
  }

  .accountSettingsUpdatedText{
    font-family: 'Overpass';
    margin-top: 1.5vw;
    margin-bottom: 2vw;
    font-size: 16px;
    font-weight: 400;
    color: #444B59;
    letter-spacing: 0.2vw;
  }
}



@media (min-width: 600px){
  .loginBackgroundImage{
    background: url("../../assets/login/loginBanner.png") right no-repeat;
    background-size: cover;
    height: 100vh;
    margin-top: -40px;
  }

  .loginButton_test{

  }
  .logoutModalContainer{
    height: 40vw;
    width: 80vw;
    margin: auto;
    margin-top: 0px;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    border-radius: 30px;
  }
  .logoutModalContainer:focus {
    outline:0!important;
  }
  .logoutBackgroundImage{
    background-image: url("../../assets/login/logoutBanner.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 40vw;
    width: 80vw;
    border-radius: 30px;
    text-align: center;
  }
  .logoutConfirmText{
    font-family: 'Poppins';
    font-size: 2.4vw;
    font-weight: 800;
    color: #024AAD;
    padding-top: 7vw;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .logoutContent{
    width: 24vw;
    margin-top: 2vw;
  }
  .logoutButtonContainer{
    margin-top: 3vw;
  }
  .logoutDisplayInlineBlock{
    display: inline-block;
  }
  .logoutButtonMargin{
    width: 4vw;
    display: inline-block;
  }





  /*//11////////////////*/
  .contactUsEmailText{
    font-size: 1.46vw;
    font-family: 'Nunito';
    font-weight: 600;
    text-align: left;
    color: #444B59;
    letter-spacing: 0.17vw;
    margin-bottom: 0.55vw;
  }
  .contactUsBackgroundImage{
    background-image: url("../../assets/login/contactUsBanner.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    margin-top: -40px;
  }


  .accountSettingsText{
    margin-top: 6.93vw;
    font-size: 2.08vw;
    font-family: 'Overpass';
    font-weight: 800;
    color: #444B59;
    text-align: center;
    margin-bottom: 2.08vw;
    letter-spacing: 0.17vw;
  }

  .accountSettingsTypeText{
    font-size: 1.25vw;
    font-family: 'Poppins';
    font-weight: 600;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: #024AAD;
    text-decoration-thickness: 0.17vw;
    text-underline-offset: 0.35vw;
    margin-bottom: 2.43vw;
  }
  .newNameText{
    font-size: 1.46vw;
    font-family: 'Nunito';
    font-weight: 600;
    text-align: left;
    color: #444B59;
    letter-spacing: 0.17vw;
    margin-bottom: 0.55vw;
  }

  .forgotPasswordClose{
    height: 4vw;
    float: right;
    margin-top: 1vw;
    margin-right: 1vw;
    cursor: pointer;
  }

  .accountSettingsUpdatedText{
    font-family: 'Overpass';
    margin-top: 1.5vw;
    margin-bottom: 2vw;
    font-size: 1.5vw;
    font-weight: 400;
    color: #444B59;
    letter-spacing: 0.2vw;
  }
}





.forgotPasswordBackgroundImage{
  background-image: url("../../assets/login/forgotPasswordBanner.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 40vw;
  width: 80vw;
  border-radius: 30px;
}

.welcomeBack{
  margin-top: 100px;
  font-size: 40px;
  font-family: 'Overpass';
  font-weight: 800;
  color: #444B59;
  letter-spacing: 4px;
}
.contactUsText{
  margin-top: 100px;
  font-size: 40px;
  font-family: 'Overpass';
  font-weight: 800;
  color: #444B59;
  text-align: center;
  margin-bottom: 60px;
}

.forgotPasswordText{
  margin-left: 4vw;
  font-size: 3vw;
  font-family: 'Overpass';
  font-weight: 800;
  color: #444B59;
  margin-top: -1vw;
}
.rememberMe{
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
}
.loginInputLabel{
  font-family: 'Nunito';
  font-weight: 600;
  color: #444B59;
}
.loginFailedMsg{
  font-family: 'Nunito';
  font-weight: 400;
  color: #D63737;
  font-size: 1.2vw;
}
.loginFailedMsgRight{
  font-family: 'Nunito';
  font-weight: 400;
  color: #D63737;
  font-size: 1.2vw;
  margin-top: 0px;
  margin-bottom: 0px;
}
.forgotPassword{
  margin-left: auto;
  color: #777;
  cursor: pointer;
  font-family: 'Nunito';
  font-weight: 600;
  color: #8699DA;
}
.forgotPassword:hover{
  text-decoration: underline;
}
.rememberMeRadio{
  margin-top: 5px;
  margin-bottom: 20px;
  margin-right: -15px;
}
.margin10{
  height: 10px;
}
.margin20{
  height: 20px;
}
.margin100{
  height: 100px;
}



.rememberMeLabel{
  font-family: 'Nunito';
  font-weight: 400;
  color: #444B59;
}
.sendButton{
  margin-left: auto!important;
}
.sendButtonContainer{
  flex-direction: row;
  display: flex;
  margin-top: 15px;
}
.confirmButtonContainer{
  flex-direction: row;
  display: flex;
  margin-top: 15px;
  margin-right: -10px!important;
}
.loginTextField{
  background-color: white;
  border-radius: 100px;
}
.loginTextFieldLong{
  background-color: white;
  border-radius: 30px;
}
.forgotPasswordModalContainer{
  height: 40vw;
  width: 80vw;
  margin: auto;
  margin-top: 0px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 30px;
}
.forgotPasswordModalContainer:focus {
  outline:0!important;
}

.forgotPasswordInputContainer{
  text-align: center;
}
.forgotPasswordInputLabel1{
  font-family: 'Nunito';
  font-weight: 400;
  color: black;
  font-size: 1.4vw;
}
.forgotPasswordInputLabel2{
  font-family: 'Nunito';
  font-weight: 400;
  color: #B3B3B3;
  font-size: 1vw;
  margin-bottom: 2vw;
}
.forgotPasswordInput{
  width: 40vw;
  background-color: white;
}
.forgotPasswordContactUs{
  color: #3A5AFF;
  text-decoration: underline;
  cursor: pointer;
}
.forgotPasswordInputSingleDigit{
  background-color: white;
  width: 7vw;
  height: 6vw;
  text-align: center;
}
.forgotPasswordTick{
  width: 5vw;
}
.forgotPasswordSuccessText{
  font-family: 'Overpass';
  margin-top: 2vw;
  margin-bottom: 2vw;
  font-size: 1.5vw;
  font-weight: 400;
  color: #444B59;
}






.newNameTextContainer{
  margin-left: -2.08vw;
  margin-right: -0.69vw;
}

.confirmButtonContainer{
  margin-top: 1.39vw;
  margin-right: -1.73vw;
}
.confirmButtonDisplayInlineBlock{
  display: inline-block;
}
.accountSettingsUpdatedModalContainer{
  height: 40vw;
  width: 80vw;
  margin: auto;
  margin-top: 0px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 50px;
}
.accountSettingsUpdatedModalContainer:focus {
  outline:0!important;
}
.accountSettingsUpdatedBackgroundImage{
  background-image: url("../../assets/login/forgotPasswordBanner.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 40vw;
  width: 80vw;
  border-radius: 50px;
}
.accountSettingsTopMargin{
  height: 5vw;
}
.accountSettingsUpdatedClose{
  height: 4vw;
  float: right;
  margin-top: 1vw;
  margin-right: 1vw;
  cursor: pointer;
}
.accountSettingsUpdatedTick{
  width: 5vw;
}
.accountSettingsUpdatedInputContainer{
  text-align: center;
  margin-top: 7vw;
}

.accountSettingsDisplayInlineBlock{
  display: inline-block;
}
