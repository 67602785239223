.imageshiftright{
  position:relative;
  left:2vw;
}

.loginBackgroundImage23333{
  position:fixed;
  top:3vh;
  left:5vw;
}
.margin1222define{
  height:12vh;
}
.margin10define{
  position:relative;
  top:30vh;
}

.joinus{
  /*position: absolute;*/
  /*width: 175px;*/
  /*height: 49px;*/
  /*left: 112px;*/
  /*top: 290px;*/

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 27px;
  /*display: flex;*/
  /*align-items: center;*/
  text-align: center;

  color: #000000;

  /*text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);*/
}

.joinus4{
  /*position: absolute;*/
  /*width: 175px;*/
  /*height: 49px;*/
  /*left: 112px;*/
  /*top: 290px;*/

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;

  color: #000000;

  /*text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);*/
}

.joinus2{

  /*width:70vw;*/
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /*display: flex;*/
  align-items: center;
  text-align: center;

  color: #000000;
}
.joinus3{

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  /*display: flex;*/
  align-items: center;
  text-align: center;

  color: #000000;
}
.joinus4{

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /*display: flex;*/
  align-items: center;
  text-align: center;

  color: #000000;
}
.containtestt{

  position:relative;
}


.joinus6{

  margin-left:8vw;
  margin-bottom: 3vh;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 20px;
  /* or 83% */

  /*display: flex;*/
  /*align-items: center;*/
  letter-spacing: 0.2px;

  color: #000000;
}
.texttest{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 36px;
  /*display: flex;*/
  /*align-items: center;*/
  /*text-align: center;*/

  color: #024AAD;


  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (min-width: 600px){
  .loginBackgroundImage3{
    /*background: url("../../assets/booth/StartingPage_with2.png") no-repeat center;*/
    background: url("../../assets/booth/StartingPage_with4.png") no-repeat center;
    background-size: contain;
    height: 100vh;
    margin-top: -70px;
  }
}
@media (max-width: 600px){
  .loginBackgroundImage3{
    background: url("../../assets/booth/StartingPage_with4.png") no-repeat center;
    background-size: cover;
    height: 100vh;
    margin-top: -70px;
  }
}





