
@media (max-width: 600px){
  .answerReviewTopicTitleBoxContainer {
    /*width: calc(100% - 80px);*/
    /*margin-left: 50px;*/
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;


    /*border-style: dotted;*/
  }

  .answerReviewLebal {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 18px;
    color: #6B6969;
    text-decoration: underline;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .answerReviewTopicBoxContainer {
    /*width: calc(100% - 80px);*/
    border-width: 2px;
    border-radius: 10px;
    border-style: solid;
    border-color: #DFDDDD;
    /*margin-left: 50px;*/
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;

    /*border-style: dotted;*/
  }

  .reviewNowText {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 18px;
  }



  .exerciseResponsiveContainer {
    margin-left: 20px;
    margin-right: 20px;
  }





  .pauseModalContainer {
    height: 80vh;
    width: 80vw;
    margin: auto;
    margin-top: 0px;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    border-radius: 30px;
  }

  .pauseModalContainer:focus {
    outline: 0 !important;
  }




  .confirmLeaveContent {
    width: 40vw;
    margin-top: 2vw;
  }

  .pauseBackgroundImage {
    background-image: url("../../assets/exercise/pauseBanner.png");
    background-repeat: no-repeat;
    background-size: cover;
    /*height: 40vw;*/
    padding-bottom:2vh;
    width: 80vw;
    border-radius: 30px;
    text-align: center;
  }
  .pauseConfirmText {
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 800;
    color: #024AAD;
    padding-top: 5vw;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .pauseContent {
    width: 40vw;
    margin-top: 2vw;
  }

  .pauseTimeSpentText {
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 800;
    color: black;
    margin-top: 1vw;
    margin-bottom: 0px;
  }

  .calibrationTestSideMenuContainer {
    /*margin-left: -90px;*/
    /*display: inline-block;*/
    /*width: 100vw;*/
    position: fixed; /*should be fixed*/
    z-index:8; /*this to ensure the drop down is above the question in mobile view */
  }



  .calibrationTestQuestionContainer {
    position: relative;
    /*top: 50%;*/
    /*-webkit-transform: translateY(-50%);*/
    /*-ms-transform: translateY(-50%);*/
    /*transform: translateY(-50%);*/
    /*margin-left: -50px;*/
    /*margin-right: 20px;*/
  }


  .multipleChoiceQuestionNum {
    color: #6B6969;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 0px;
    display: inline-block;
  }

  .multipleChoiceQuestion {
    color: black;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    line-height: 3vw;
    display: inline;
    line-height: 2.4vw;
  }


  .optionText {
    margin-left: 0.3vw;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 12px;
  }


  .optionAnswer {
    margin-left: 3vw;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 15px;
  }


  .multipleChoiceEmptyMsg {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 12px;
    color: #FF0000;
    display: inline-block;
    margin-left: 2vw;
  }
  .multipleChoiceTopicText {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 14px;
    color: #6B6969;
    display: inline-block;
    margin-left: 2vw;
  }
  .multipleChoiceDifficultText {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 12px;
    color: #6B6969;
    font-style: italic;
    margin-left: 2vw;
    display: inline-block;
  }
  .answerElaborationTitle {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 2vw;
    margin-top: 0;
    display: inline-block;
  }
  .answerElaborationDetail {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 0;
    margin-top: 0;
  }


  .multipleChoiceDontKnowText {
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 12px;
    color: #3497CE;
    margin-left: 0.8vw;
    margin-top: 0;
    margin-bottom: 0;
  }
  .multipleChoiceBookmarkText {
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 12px;
    color: #3497CE;
    margin-left: 0.8vw;
    margin-top: 0;
    margin-bottom: 0;
  }

  .progressContainer {

    position:fixed;
    top:0;


    width: 100%;
    background-color: #FFDB8E;



    /*height: 48px;*/

    /*make round*/
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;


    padding-top: 10px;
    padding-bottom: 10px;
    /*height: calc(100vh - 80px);*/
    overflow: scroll;


  }
  .progressFloatRight {
    display:none;
    transition: height 2s;
    /*float: right;*/
    /*width: calc(100% - 90px);*/
  }


  .exerciseBackgroundImage {
    background-image: url("../../assets/exercise/exerciseBanner.png");
    background-repeat: no-repeat;
    background-size: cover;
    /*height: calc(100vh - 60px);*/
    /*overflow: scroll;*/
  }
  .exerciseBackgroundImage2 {
    background-image: url("../../assets/exercise/exerciseBanner.png");
    /*background-repeat: repeat;*/
    background-size: cover;
    padding-top: 5vh;
    height: calc(150vh);
    /*overflow: scroll;*/
  }
  .progressTopImageContainer {
    /*width: 30%;*/
    /*text-align: center;*/

    display: flex;
    justify-content: center;
  }
  .progressTopImage {
    width:30vw;
    /*width: 80%;*/
    /*margin-top: 20px;*/
  }

  .timerContainer {
    /*width:30vw;*/
    padding-right:100px;
    padding-left:100px;
    margin-top: 1vw;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1vw;

  }
  .progressClockSmall {
    width: 2.6vw;
    margin-right: 0.8vw;
  }

  .timerTextSmall {
    color: #43991B;
    font-family: 'Poppins';
    font-weight: 800;
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 1.6vw;
    text-align: center;
  }

  .progressBtnContainer {
    /*width:40vw;*/
    display: flex;
    justify-content: center;
    background-color: white;
    border-radius: 10px;
    padding: 0.5vw 2vw;
    cursor: pointer;
    /*margin-bottom: 0vw;*/
    /*margin-left: 10px;*/
    /*margin-right: 10px;*/
  }
  .progressPause {
    width: 2vw;
    margin-right: 0.8vw;
  }
  .progressPauseText {
    color: #FDA718;
    font-size: 20px;
    margin-top: 0vw;
    margin-bottom: 0px;
    /*width: 10vw;*/
    text-align: center;
  }
  .progressSaveBtnContainer {
    display: flex;
    justify-content: center;
    background-color: white;
    border-radius: 10px;
    padding: 0.5vw 2vw;
    cursor: pointer;
    /*margin-bottom: 0vw;*/
    /*margin-top: 0.5vw;*/
    /*margin-left: 10px;*/
    /*margin-right: 10px;*/
  }
  .progressSaveDraft {
    width: 1.8vw;
    margin-left: 0.1vw;
    margin-right: 0.9vw;
  }
  .progressSaveDraftText {
    color: #4BC4EF;
    font-size: 20px;
    margin-top: 0vw;
    margin-bottom: 0px;
    /*width: 10vw;*/
    text-align: center;
  }

  .progressSaveDraftTextWhite {
    color: white;
    font-size: 20px;
    margin-top: 0vw;
    margin-bottom: 0px;
    /*width: 10vw;*/
    text-align: center;
  }

  .progressQuestionTitleContainer {
    display: flex;
    margin-bottom: 0.5vw;
    margin-left: 10px;
  }
  .progressIcon {
    width: 18px;
    margin-top: -0.2vw;
  }

  .progressTitle {
    font-size: 18px;
    margin-left: 0.5vw;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'Poppins';
    font-weight: 700;
  }

  .progressQuestionNumContainerOuter{

    margin-left: calc(1.5vw);
  }

  .progressQuestionNum {
    font-family: 'Poppins';
    font-weight: 800;
    background-color: white;
    width:30px;
    height:30px;
    /*width: calc(10vw - 10px);*/
    /*height: calc(3.4vw - 10px);*/
    /*line-height: calc(3.6vw - 10px);*/
    border-radius: 100vw;
    /*font-size: 12px;*/
    text-align: center;
    cursor: pointer;
    z-index: 3;
  }

  .MobileprogressDownArrow11{
    display:block;
    margin:auto;
  }


  .multipleChoiceTopContainer {
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
  }

  .multipleChoiceTopRightContainer {
    /*margin-left: auto;*/
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    /*margin-top: -2vw;*/
  }
  .multipleChoiceDontKnowContainer {
    display: flex;
    flex-direction: row;
    margin-right: 2.4vw;
    cursor: pointer;
    height:20px;
    /*width: 11vw;*/
    padding-bottom:20px;
    align-items: center;
    justify-content: center;
  }

  .multipleChoiceBookamrkContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /*width: 11vw;*/
    padding-bottom:20px;
  }

  .progressQuestionNumText {
    /*margin-top: calc(-2vw + 5px);*/
  }

  .fillInTheBlankIntroduction {
    color: black;
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 12px;
    margin-top: 1.2vw;
    margin-bottom: 1.2vw;
  }

  .fillInMultipleBlankOptionContainer {
    background-color: white;
    border-radius: 30px;
    padding: 1vw 5vw;
    margin-bottom: 1vw;
    margin-left: 1vw;
    margin-right: 1vw;
    text-align: center;
    border: 3px solid #F6C840;

  }
  .fillInMultipleBlankOption {
    display: inline-block;
    margin-left: 2.5vw;
    margin-right: 2.5vw;
    line-height: 3vw;
    overflow-wrap: break-word;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 12px;
  }

  .fillInMultipleBlankIntroduction {
    color: black;
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 3vw;
  }


  .fillInMultipleBlankQuestion {
    color: black;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 1vw;
    line-height: 3vw;
    display: inline;
  }

  .fillInMultipleBlankQuestionContainer{
    line-height: normal;
  }

  .fillInMultipleBlankInputTextField {
    width: 15vw;
    margin-left: 0.5vw !important;
    margin-right: 0.5vw !important;
    /*margin-top: 12px !important;*/
  }

  .fillInMultipleBlankAnswer {
    position: absolute;
    text-align: center;
    display: inline-block;
    line-height: 0.2vw;
    font-size: 12px;
    color: #D63737;
    /*width: calc(10vw);*/
    margin-left: calc(-15vw);
  }

  .questionBankModalContainer {
    background-color: white;
    border-radius: 30px;
    height: 80vh;
    width: 80vw;
  }


  .questionBankModalBook {
    width: 30vw;
    /*transform: rotate(-20deg);*/
    /*margin-left: 5vw;*/
  }


  .questionBankModalDifficultyTitle {
    font-family: 'Poppins';
    font-weight: 800;
    font-size: 16px;
    /*text-align: left;*/
  }


  .questionBankModalDifficulty {
    /*width: 60vw;*/
    border-radius: 15px;
    color: white;
    padding: 1.5vw;
    text-align: center;
    margin-bottom: 1vw;
    cursor: pointer;
  }

  .questionBankModalDifficultyText {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .questionBankModalDifficultyDescription {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 0;
  }


  .questionBankModalSkip {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 12px;
  }


  .progressQuestionNumContainer {
    display: inline-flex;
    flex-direction: row;
    margin-bottom: 5vw;
  }

  .answerElaborationAnswerTextOption {
    margin-left: 0.4vw;
    margin-right: 0.4vw;
    background-color: white;
    border-radius: 10000px;
    color: #23FF00;
    /*width: 1.8vw;*/
    /*height: 1.8vw;*/
    padding:4px;
    line-height: 1.8vw;
    display: inline-block;
    text-align: center;
  }


  .answerElaborationContainer {
    color: white;
    text-align: left;
    margin:20px;
    padding: 50px 0px 20px 0;
    /*margin-top: -16.5vw;*/
    /*margin-left: 4vw;*/
    /*margin-right: 3vw;*/
  }

  .answerElaborationContainerLevel3 {
    color: white;
    text-align: left;
    padding:20px;
    /*margin-top: -17.5vw;*/
    /*margin-left: 4vw;*/
    /*margin-right: 3vw;*/
  }



  .calibrationTestResultTitle {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 18px;
    margin-top: -1vw;
  }
}
/*breakpoint between media*/
@media (min-width: 600px){

  .answerReviewTopicTitleBoxContainer {
    width: calc(100% - 80px);
    margin-left: 50px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .answerReviewLebal {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 18px;
    color: #6B6969;
    text-decoration: underline;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .answerReviewTopicBoxContainer {
    width: calc(100% - 80px);
    border-width: 2px;
    border-radius: 10px;
    border-style: solid;
    border-color: #DFDDDD;
    margin-left: 50px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .reviewNowText {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 0.8vw;
  }












  .exerciseResponsiveContainer {
    margin-left: 150px;
    margin-right: 150px;
  }







  .pauseModalContainer {
    height: 40vw;
    width: 80vw;
    margin: auto;
    margin-top: 0px;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    border-radius: 30px;
  }

  .pauseModalContainer:focus {
    outline: 0 !important;
  }


  .confirmLeaveContent {
    width: 20vw;
    margin-top: 2vw;
  }


  .pauseBackgroundImage {
    background-image: url("../../assets/exercise/pauseBanner.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 40vw;
    width: 80vw;
    border-radius: 30px;
    text-align: center;
  }
  .pauseConfirmText {
    font-family: 'Poppins';
    font-size: 2.4vw;
    font-weight: 800;
    color: #024AAD;
    padding-top: 5vw;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .pauseContent {
    width: 24vw;
    margin-top: 2vw;
  }

  .pauseTimeSpentText {
    font-family: 'Poppins';
    font-size: 2.4vw;
    font-weight: 800;
    color: black;
    margin-top: 1vw;
    margin-bottom: 0px;
  }


  .calibrationTestSideMenuContainer {
    margin-left: -90px;
    display: inline-block;
    width: 25vw;
    position: fixed;
  }

  .calibrationTestQuestionContainer {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-left: -50px;
    margin-right: 20px;
  }


  .multipleChoiceQuestionNum {
    color: #6B6969;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 1.4vw;
    margin-top: 0px;
    margin-bottom: 0px;
    display: inline-block;
  }

  .multipleChoiceQuestion {
    color: black;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1.2vw;
    line-height: 3vw;
    display: inline;
    line-height: 2.4vw;
  }

  .optionText {
    margin-left: 0.3vw;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1.2vw;
  }


  .optionAnswer {
    margin-left: 3vw;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1.2vw;
  }  .optionAnswer2 {
         font-family: 'Poppins';
         font-style: normal;
         font-weight: 400;
         font-size: 40px;
  }


  .multipleChoiceEmptyMsg {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 1.2vw;
    color: #FF0000;
    display: inline-block;
    margin-left: 2vw;
  }
  .multipleChoiceTopicText {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 1.2vw;
    color: #6B6969;
    display: inline-block;
    margin-left: 2vw;
  }
  .multipleChoiceDifficultText {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 1.2vw;
    color: #6B6969;
    font-style: italic;
    margin-left: 2vw;
    display: inline-block;
  }
  .answerElaborationTitle {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 1.2vw;
    margin-bottom: 2vw;
    margin-top: 0;
    display: inline-block;
  }

  .answerElaborationDetail {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 1.2vw;
    margin-bottom: 0;
    margin-top: 0;
  }

  .multipleChoiceDontKnowText {
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 1vw;
    color: #3497CE;
    margin-left: 0.8vw;
    margin-top: 0;
    margin-bottom: 0;
  }
  .multipleChoiceBookmarkText {
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 1vw;
    color: #3497CE;
    margin-left: 0.8vw;
    margin-top: 0;
    margin-bottom: 0;
  }


  .progressContainer {
    width: 100%;
    background-color: #FFDB8E;
    padding-top: 10px;
    padding-bottom: 10px;
    height: calc(100vh - 80px);
    overflow: scroll;
  }
  .progressFloatRight {
    float: right;
    width: calc(100% - 90px);
  }


  .exerciseBackgroundImage {
    background-image: url("../../assets/exercise/exerciseBanner.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100vh - 60px);
    overflow: scroll;
  }
  .exerciseBackgroundImage2 {
    background-image: url("../../assets/exercise/exerciseBanner.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100vh - 60px);
    overflow: scroll;
  }

  .progressTopImageContainer {
    width: 100%;
    text-align: center;
  }

  .progressTopImage {
    width: 80%;
    margin-top: 20px;
  }

  .timerContainer {
    margin-top: 1vw;
    display: flex;
    justify-content: center;
    margin-bottom: 1vw;
  }
  .progressClockSmall {
    width: 2.6vw;
    margin-right: 0.8vw;
  }

  .timerTextSmall {
    color: #43991B;
    font-family: 'Poppins';
    font-weight: 800;
    font-size: 2vw;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 1.6vw;
    text-align: center;
  }


  .progressBtnContainer {
    display: flex;
    justify-content: center;
    background-color: white;
    border-radius: 0.5vw;
    padding: 0.5vw 2vw;
    cursor: pointer;
    margin-bottom: 0vw;
    margin-left: 10px;
    margin-right: 10px;
  }
  .progressPause {
    width: 2vw;
    margin-right: 0.8vw;
  }
  .progressPauseText {
    color: #FDA718;
    font-size: 1.4vw;
    margin-top: 0.2vw;
    margin-bottom: 0px;
    width: 10vw;
    text-align: center;
  }

  .progressSaveBtnContainer {
    display: flex;
    justify-content: center;
    background-color: white;
    border-radius: 0.5vw;
    padding: 0.5vw 2vw;
    cursor: pointer;
    margin-bottom: 0vw;
    margin-top: 0.5vw;
    margin-left: 10px;
    margin-right: 10px;
  }
  .progressSaveDraft {
     width: 1.8vw;
     margin-left: 0.1vw;
     margin-right: 0.9vw;
   }
  .progressSaveDraftText {
    color: #4BC4EF;
    font-size: 1.4vw;
    margin-top: 0.1vw;
    margin-bottom: 0px;
    width: 10vw;
    text-align: center;
  }

  .progressSaveDraftTextWhite {
    color: white;
    font-size: 1.4vw;
    margin-top: 0.1vw;
    margin-bottom: 0px;
    width: 10vw;
    text-align: center;
  }
  .progressQuestionTitleContainer {
    display: flex;
    margin-bottom: 0.5vw;
    margin-left: 10px;
  }

  .progressIcon {
    width: 1.3vw;
    margin-top: -0.2vw;
  }

  .progressTitle {
    font-size: 1.2vw;
    margin-left: 0.5vw;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'Poppins';
    font-weight: 700;
  }
  .progressQuestionNumContainerOuter{
    margin-left: calc(1.5vw - 15px);
  }
  .progressQuestionNum {
    font-family: 'Poppins';
    font-weight: 800;
    background-color: white;
    width: calc(3.4vw - 10px);
    height: calc(3.4vw - 10px);
    line-height: calc(3.6vw - 10px);
    border-radius: 100vw;
    font-size: 1.4vw;
    text-align: center;
    cursor: pointer;
    z-index: 3;
  }

  .multipleChoiceTopContainer {
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
  }
  .multipleChoiceTopRightContainer {
    margin-left: auto;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    margin-top: -2vw;
  }


  .multipleChoiceDontKnowContainer {
    display: flex;
    flex-direction: row;
    margin-right: 2.4vw;
    cursor: pointer;
    width: 11vw;
    align-items: center;
    justify-content: center;
  }

  .multipleChoiceBookamrkContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 11vw;
  }

  .progressQuestionNumText {
    margin-top: calc(-2vw + 5px);
  }


  .fillInTheBlankIntroduction {
    color: black;
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 1.2vw;
    margin-top: 1.2vw;
    margin-bottom: 1.2vw;
  }

  .fillInMultipleBlankOptionContainer {
    background-color: white;
    border-radius: 1vw;
    padding: 1vw 5vw;
    margin-bottom: 1vw;
    margin-left: 1vw;
    margin-right: 1vw;
    text-align: center;
    border: 3px solid #F6C840;
  }


  .fillInMultipleBlankOption {
    display: inline-block;
    margin-left: 2.5vw;
    margin-right: 2.5vw;
    line-height: 3vw;
    overflow-wrap: break-word;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1.2vw;
  }

  .fillInMultipleBlankIntroduction {
    color: black;
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 1.2vw;
    margin-bottom: 3vw;
  }

  .fillInMultipleBlankQuestion {
    color: black;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1.2vw;
    margin-bottom: 1vw;
    line-height: 3vw;
    display: inline;
  }
  .fillInMultipleBlankInputTextField {
    width: 10vw;
    margin-left: 0.5vw !important;
    margin-right: 0.5vw !important;
    margin-top: 1vw !important;
  }
  .fillInMultipleBlankAnswer {
    position: absolute;
    text-align: center;
    display: inline-block;
    line-height: 0.2vw;
    font-size: 1vw;
    color: #D63737;
    width: calc(10vw);
    margin-left: calc(-10.5vw - 1.3vw);
  }
  .questionBankModalContainer {
    background-color: white;
    border-radius: 30px;
    height: 40vw;
    width: 80vw;
  }

  .questionBankModalBook {
    width: 12vw;
    transform: rotate(-20deg);
    margin-left: 5vw;
  }


  .questionBankModalDifficultyTitle {
    font-family: 'Poppins';
    font-weight: 800;
    font-size: 2vw;
    text-align: left;
  }

  .questionBankModalDifficulty {
    width: 35vw;
    border-radius: 15px;
    color: white;
    padding: 1.5vw;
    text-align: center;
    margin-bottom: 1vw;
    cursor: pointer;
  }

  .questionBankModalDifficultyText {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1.2vw;
    margin-top: 0;
    margin-bottom: 0;
  }

  .questionBankModalDifficultyDescription {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1vw;
    margin-top: 0;
    margin-bottom: 0;
  }


  .questionBankModalSkip {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1vw;
  }



  .progressQuestionNumContainer {
    display: inline-flex;
    flex-direction: row;
    margin-bottom: 0.1vw;
  }

  .answerElaborationAnswerTextOption {
    margin-left: 0.4vw;
    margin-right: 0.4vw;
    background-color: white;
    border-radius: 10000px;
    color: #23FF00;
    width: 1.8vw;
    height: 1.8vw;
    line-height: 1.8vw;
    display: inline-block;
    text-align: center;
  }




  .answerElaborationContainer {
    color: white;
    text-align: left;
    margin-top: -16.5vw;
    margin-left: 4vw;
    margin-right: 3vw;
  }
  .answerElaborationContainerLevel3 {
    color: white;
    text-align: left;
    margin-top: -17.5vw;
    margin-left: 4vw;
    margin-right: 3vw;
  }

  .calibrationTestResultTitle {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 1.4vw;
    margin-top: -1vw;
  }
}





.progressContainer2 {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  height: calc(100vh - 80px);
  overflow: scroll;
}









.progressClock {
  width: 3.8vw;
  margin-right: 0.8vw;
}

.progressBookmarkContainer {
  text-align: center;
  width: calc(3.4vw - 10px);
  height: calc(3.4vw - 10px);
  margin-top: calc(-1.4vw + 5px);
}

.progressBookmark {
  height: 1.2vw;
}


.timerText {
  color: #43991B;
  font-family: 'Poppins';
  font-weight: 800;
  font-size: 2.6vw;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 2vw;
  text-align: center;
}












.progressQuestionContainer {
  margin-top: 1vw;
  position: relative;
}

.progressQuestionContainer2 {
  margin-top: 1vw;
  position: relative;
  background-color: white;
  padding-top: 1vw;
  margin-top: 2vw;
  padding-bottom: 1vw;
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
}












.progressLineRightEmpty {
  width: 0.4vw;
  height: 0.4vw;
  margin-bottom: 1.4vw;
  margin-top: calc((3.4vw - 10px)/2 - 0.2vw);
  display: inline-block;
}

.progressLineRight {
  width: 0.4vw;
  height: 0.4vw;
  background-color: #FDA718;
  margin-bottom: 1.4vw;
  margin-top: calc((3.4vw - 10px)/2 - 0.2vw);
  display: inline-block;
}

.progressLineRightLong {
  position: absolute;
  width: 10vw;
  height: 0.4vw;
  background-color: #FDA718;
  margin-bottom: 1.4vw;
  margin-top: calc((3.4vw - 10px)/2 - 0.2vw);
  display: inline-block;
  right: 0;
}

.progressLineLeft {
  width: 0.4vw;
  height: 0.4vw;
  background-color: #FDA718;
  margin-bottom: 1.4vw;
  display: inline-block;
  margin-top: calc((3.4vw - 10px)/2 - 0.2vw);
}

.progressLineLeftEmpty {
  width: 0.4vw;
  height: 0.4vw;
  margin-bottom: 1.4vw;
  display: inline-block;
  margin-top: calc((3.4vw - 10px)/2 - 0.2vw);
}

.progressLineLeftLong {
  width: 2.2vw;
  margin-left: -1.8vw;
  height: 0.4vw;
  background-color: #FDA718;
  margin-bottom: 1.4vw;
  display: inline-block;
  margin-top: calc((3.4vw - 10px)/2 - 0.2vw);
}

.questionArrow {
  width: 50%;
  cursor: pointer;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.questionArrowAnswer {
  height: 4vw;
  margin-top: 2vw;
  cursor: pointer;
  position: relative;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.questionContainer {
  padding-left: 2vw;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.multipleChoiceContainer {
  text-align: left;
  background-color: white;
  border-radius: 1vw;
  padding: 2vw;
  box-shadow: 0px 4px 5px #ccc;
}




.multipleChoiceQuestionUnderline {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 1.2vw;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  display: inline;
}



.multipleChoiceDifficult {
  display: inline-block;
}



.multipleChoiceDifficultStar {
  display: inline-block;
  width: 1.6vw;
  margin-left: 0.5vw;
  margin-bottom: -0.2vw;
}

.multipleChoiceRadioDisable {
  width: 4vw;
  height: 4vw;
  margin-left: -4vw;
  z-index: 10;
}

.optionContainer {
  padding: 0.8vw 1vw;
  background-color: white;
  border-radius: 1vw;
  border: 1px solid #CAC7C7;
  margin-top: 0.5vw;
  margin-right: 1vw;
  padding-left: 1.5vw;
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}

.optionContainer2 {
  /*padding: 0.8vw 1vw;*/
  background-color: white;
  border-radius: 1vw;
  border: 1px solid #CAC7C7;
  margin-top: 0.5vw;
  /*margin-right: 1vw;*/
  padding-left: 1.5vw;
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}

.optionTextContainer {
  /*position: relative;*/
  /*top:11px*/
  vertical-align: middle;
}

/*.questionImageExampleContainer {*/
/*  !* background-image: url("../../assets/exercise/questionImageExample.png"); *!*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*  height: 100%;*/
/*  width: 100%;*/
/*  border-radius: 2vw;*/
/*  background-position: center;*/
/*}*/



.questionImageExampleContainer {
  background-image: url("../../assets/exercise/fillInTheBlankExample.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
  border-radius: 2vw;
  background-position: center;
}

.fillInTheBlankPen {
  width: 1vw;
}

.fillInTheBlankInputTextField {
  width: 80%;
}

.fillInTheBlankTextFieldInputAdornment {
  margin-right: 0.5vw !important;
}

.fillInTheBlankInputTextFieldContainer {
  margin-top: 9vw;
}

.fillInTheBlankQuestionContainer {
  margin-right: 1vw;
}

.fillInMultipleBlankContainer {
  text-align: left;
  background-color: white;
  border-radius: 1vw;
  padding: 1vw 2vw;
  margin-left: 1vw;
  margin-right: 1vw;
  box-shadow: 0px 4px 5px #ccc;
}



.fillInMultipleBlankPen {
  width: 1.2vw;
  margin-bottom: 0.5vw;
}







.questionCenterButtonContainer {
  width: 100%;
  text-align: center;
  margin-top: 2vw;
}

.fillInTheBlankCorrect {
  width: 2.5vw;
  margin-top: -0.3vw;
}

.fillInMultipleBlankCorrect {
  width: 1.5vw;
  margin-top: -0.3vw;
}







.pauseButtonContainer {
  margin-top: 1.5vw;
}



.timesUpContent {
  width: 32vw;
  margin-top: 2vw;
  margin-bottom: 2vw;
}





.claibrationTestQuestionInnerContainer {
  padding-top: 15px;
}

.claibrationTestCenterButtonContainer {
  width: 100%;
  text-align: center;
  margin-top: 3vw;
}

.claibrationTestBottomMargin {
  height: 5vw;
}

.progressBottomMargin {
  height: 2vw;
}

.answerContainerImg {
  width: 100%;
  margin-top: -1vw;
  vertical-align: middle;
}






.answerElaborationAnswerTextUnderline {
  color: #23FF00;
  text-decoration: underline;
}

.correctElaborationImg {
  width: 1.6vw;
  margin-top: -0.3vw;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5vw;
}

.multipleChoiceAnswerImg {
  margin-left: auto;
  display: flex;
  vertical-align: middle;
  width: 2.4vw;
}

.calibrationTestResultTitleContainer {
  background-color: #FFE351;
  border-radius: 1000px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  height: 1.4vw;
  margin-bottom: 2.4vw;

}



.calibrationTestResultContainer {
  justify-content: center;
  display: flex;
  position: relative;
  margin-top: 3vw;
}

.calibrationTestResultContainerInner {
  width: 820px;
  align-self: center;
  margin-left: 20px;
  margin-right: 20px;
}

.calibrationTestResultSingleContainer {
  width: calc(100% - 40px);
  border-radius: 15px;
  box-shadow: 1px 1px 5px #eee;
  padding-left: 20px;
  padding-bottom: 15px;
  background-color: white;
}

.calibrationTestResultSingleMargin {
  height: 10px;
  width: 100%;
}

.calibrationTestResultScore {
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 1.6vw;
}

.calibrationTestResultNextBtn {
  justify-content: center;
  display: flex;
  margin-top: 1vw;
  margin-bottom: 3vw;
}



.exerciseTitleContainer {
  background-color: #AEF4F4;
  border-radius: 1000px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  height: 20px;
}

.questionBankBanner {
  width: 100%;
  margin-bottom: 3vw;
}

.questionBankBook {
  width: 85%;
  cursor: pointer;
}

.questionBankBookContainer {
  display: flex;
  justify-content: center;
}

.questionBankBookContainerInner {
  margin-top: calc(80px - 30vw);
  width: 90%;
}














.multipleChoiceDontKnowSVG {
  height: 2.3vw;
}




.multipleChoiceBookmarkSVG {
  height: 2.2vw;
}





.multipleChoiceBookamrkContainerNoPointer {
  display: flex;
  flex-direction: row;
}






.reviewNowContainer {
  width: calc(100% - 60px);
  border-radius: 20px;
  background-color: #FFE351;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}



.answerReviewTopicBoxText {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: pre-line;
}

.answerReviewBottom {
  height: 60px;
}

.forceGoBackConfirmText {
  font-family: 'Poppins';
  font-size: 2.4vw;
  font-weight: 800;
  color: #024AAD;
  padding-top: 5vw;
  margin-top: 0px;
  margin-bottom: 0px;
}

.calibrationTestProgressContainer {
  padding: 0px;
  width: 100%;
  height: calc(100vh - 60px);
  overflow: scroll;
}

.calibrationTestProgressNumContainer {
  padding-top: 2vw;
  width: calc(100% + 10px);
  margin-left: -10px;
  background-color: white;
  border-top-left-radius: 2vw;
  border-top-right-radius: 2vw;
  padding-bottom: 3vw;
  margin-top: 1vw;
}

.calibrationTestProgressNumContainerInner {
  padding-bottom: 0.5vw;
  padding-left: 0.5vw;
}

.calibartionTestResultByTopic {
  padding-left: 20px;
  padding-right: 20px;
  background-color: white;
}

.calibrationTestResultText {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 1vw;
  margin-top: 0;
  margin-bottom: 0;
  color: #6B6969;
}

.calibrationTestResultDottedLine {
  border-bottom: 1px dotted black;
  height: 1px;
  width: 100%;
  margin-top: 0.8vw;
  margin-bottom: 0.8vw;
}

.calibrationTestResultMargin {
  height: 5px;
}

.calibrationTestProgressTopImage {
  z-index: -1;
  width: 100%;
  margin-top: 0px;
  position: relative;
}

.calibrationTestResultGrade {
  background-color: white;
  border-radius: 1000px;
  width: 7vw;
  height: 7vw;
  text-align: center;
  box-shadow: 0px 2px 5px #bbb;
  margin-top: -8vw;
  margin-left: 10vw;
}

.calibrationTestResultGradeText {
  color: #A85757;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 4vw;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 7vw;
}

.calibrationTestResultWhiteBackground {
  width: calc(100%);
  height: 50vh;
  background-color: white;
  position: absolute;
  bottom: 0;
  z-index: -1;
}



.calibrationTestVideoContainer {
  width: 1080px;
}

.calibrationTestVideoBotomMargin {
  height: 20px;
}


.similarQuestionContainer{
  float: right;
  margin-top: -3.5vw;
}
.similarQuestionContainer2{
  float: right;
  margin-top: -3.5vw;
  margin-right: 1vw;
}
