body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*!* width *!*/
/*::-webkit-scrollbar {*/
/*    width: 10px;*/
/*    background:  white;*/
/*    border-radius: 10px;*/
/*}*/

/*!* Track *!*/
/*::-webkit-scrollbar-track {*/
/*    !*box-shadow: inset 0 0 5px grey;*!*/
/*    border-radius: 10px;*/
/*}*/

/*!* Handle *!*/
/*::-webkit-scrollbar-thumb {*/
/*    background:  #F99B2C;*/

/*    border-radius: 50px;*/
/*}*/
