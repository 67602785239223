
.menuHeight30 {
  height: 30px;
}

.menuHeight70 {
  height: 20vh;
}

.menuHeight60 {
  height: 60px;
}

.menuRight {
  margin-left: auto;
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  margin-top: -20px;
}


@media (max-width: 600px){


  .menuLogoLeft {
    position:fixed;
    z-index: 1000;
    height: 20px;
    left: 20px;
    top:20px;
    /*margin-bottom: 10px;*/
    /*margin-top: 10px;*/
    cursor: pointer;
    /*margin-left: -10px;*/
  }


  .menuLogo {
    height: 40px;
    /*margin-bottom: 10px;*/
    margin-top: 10px;
    cursor: pointer;
    /*margin-left: -10px;*/
  }
  .menuContainer {
    width: calc(100% - 40px);


    flex-direction: row;
    display: flex;
    justify-content: flex-start;


    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    position: fixed;
    height: 40px;
    background-color: white;
    z-index: 100;
    /*box-shadow: 1px 2px 5px #bbb;*/


    /*background-color: rgba(255,255,255,0);*/







    /*width: calc(100% - 40px);*/
    /*flex-direction: row;*/
    /*display: flex;*/
    /*justify-content: flex-start;*/
    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
    /*padding-top: 20px;*/
    /*position: fixed;*/
    /*height: 40px;*/
    /*background-color: white;*/
    /*z-index: 100;*/
    /*box-shadow: 1px 2px 5px #bbb;*/
  }

  .sideMenuContainer {

    position: fixed;
    top:0px;
    background: url("../../assets/menu/sideMenuBanner_m.svg") bottom;
    background-repeat: no-repeat;
    background-size: cover;
    /*border-top-right-radius: 30px;*/
    /*border-bottom-right-radius: 30px;*/




    /*/////////////////////////////////////////////////*/
    /*this two have same*/

    /*1*/

    height: calc(100vh);
    box-sizing: border-box;

    /*2*/

    /*height: 100%;*/
    /*min-height: 100%;*/
    /*box-sizing: border-box;*/
    /*overflow-x: hidden;*/
    /*overflow-y: hidden;*/

    /*////////////////////////////////////////////////*/


    width: 80px;
    text-align: center;
    padding-top: 110px;
    z-index: 190000;
    transition: width 0.3s;
  }

  #fordisplayNoneWhenMobileSmall{
    display: none;
  }
  /*no need the short version*/

  .sideMenuDashboardBarLong {
    height: 40px;
    width: 100%;
    background-color: #BF2828;
    position: absolute;
    left: 99vw;
  }

  .sideMenuResultBarLong {
    height: 40px;
    width: 10px;
    background-color: #FFA41C;
    position: absolute;
    left: 99vw;
  }

  .sideMenuAsnwerResultBarLong {
    height: 40px;
    width: 10px;
    background-color: #248914;
    position: absolute;
    left: 99vw;
  }


  .sideMenuLearningVideoBarLong {
    height: 40px;
    width: 10px;
    background-color: #2889BF;
    position: absolute;
    left: 99vw;
  }

  .sideMenuExerciseBarLong {
    height: 40px;
    width: 10px;
    background-color: #BC28BF;
    position: absolute;
    left: 99vw;
  }

  .sideMenuAccountSettingBarLong {
    height: 40px;
    width: 10px;
    background-color: #4F4F4F;
    position: absolute;
    left: 99vw;
  }

  .sideMenuContactUsBarLong {
    height: 40px;
    width: 10px;
    background-color: #202C9E;
    position: absolute;
    left: 99vw;
  }

  .sideMenuBottomImg {
    position: absolute;
    bottom: 0px;
    right:25px;
    z-index: 10;
    width: 180px;
    margin-bottom: 0px;
    /*margin-left: -15px;*/
  }



  /*/////////////////////////////// logout for not wide screen*/
  .logoutContainerSideBar {
    cursor: pointer;

  }
  .menuLogoutSideBar {
    /*display: absolute ;*/
    height: 20px;

    margin-left: 10px;
    margin-bottom: 30px;
    position: absolute;

    bottom: 0%;
    /*transform: translateY(-50%);*/
  }

  .logoutTextSideBar {
    /*display: inline-block;*/
    /*margin-left: 10px;*/
    /*margin-right: 10px;*/
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
    /*vertical-align: middle;*/
    /*margin-left: 10px;*/
    /*margin-bottom: 30px;*/
    margin-top:50px;
    margin-left: 50px;
    margin-bottom: 30px;

    position: absolute;

    bottom: -0.3%;

  }
  /*///////////////////////////////////////////////////////////////*/
}



@media (min-width: 600px){

    .menuLogo1 {
        /*border: #23FF00 solid 1px;*/
        display: flex;
        justify-content: center;
        align-items: center;


        /*margin-left: auto;*/
        /*flex-direction: row;*/
        /*display: flex;*/
        /*justify-content: flex-start;*/
        margin-top: -20px;
        /*height: 40px;*/
        /*margin-bottom: 10px;*/
        /*margin-top: -10px;*/
        /*cursor: pointer;*/
        /*margin-left: -10px;*/
    }  .menuLogo2 {
               font-family: 'Poppins';
               font-style: normal;
               font-weight: 700;
               font-size: 30px;
               line-height: 40px;
               /* or 100% */


               color: #000000;

           }.menuLogo3 {

    margin-left: 15px;
                           font-family: 'Poppins';
                           font-style: normal;
                           font-weight: 700;
                           font-size: 20px;
                           line-height: 40px;
                           /* identical to box height, or 133% */


                           color: #08B71A;

           }
  .menuLogo {
    height: 40px;
    margin-bottom: 10px;
    margin-top: -10px;
    cursor: pointer;
    margin-left: -10px;
  }
  .menuContainer {
    height: 40px;

    width: calc(100% - 40px);
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    position: fixed;

    background-color: white;
    z-index: 100;
    box-shadow: 1px 2px 5px #bbb;
  }
  .sideMenuContainer {
    position: fixed;
    background-image: url("../../assets/menu/sideMenuBanner.svg");
    background-repeat: no-repeat;
    background-size: cover;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    height: calc(100vh - 170px);
    width: 80px;
    text-align: center;
    padding-top: 110px;
    z-index: 191;
    transition: width 0.3s;
  }

  .sideMenuDashboardBarLong {
    height: 40px;
    width: 10px;
    background-color: #BF2828;
    position: absolute;
    left: 208px;
  }

  .sideMenuResultBarLong {
    height: 40px;
    width: 10px;
    background-color: #FFA41C;
    position: absolute;
    left: 208px;
  }


  .sideMenuAsnwerResultBarLong {
    height: 40px;
    width: 10px;
    background-color: #248914;
    position: absolute;
    left: 208px;
  }

  .sideMenuLearningVideoBarLong {
    height: 40px;
    width: 10px;
    background-color: #2889BF;
    position: absolute;
    left: 208px;
  }

  .sideMenuExerciseBarLong {
    height: 40px;
    width: 10px;
    background-color: #BC28BF;
    position: absolute;
    left: 208px;
  }

  .sideMenuAccountSettingBarLong {
    height: 40px;
    width: 10px;
    background-color: #4F4F4F;
    position: absolute;
    left: 208px;
  }

  .sideMenuContactUsBarLong {
    height: 40px;
    width: 10px;
    background-color: #202C9E;
    position: absolute;
    left: 208px;
  }

  .sideMenuBottomImg {
    position: absolute;
    bottom: 0px;
    z-index: 10000000;
    width: 180px;
    margin-bottom: 0px;
    margin-left: -15px;
  }






  /*/////////////////////////////// logout for wide screen*/
  .logoutContainer {
    cursor: pointer;
  }

  .menuLogout {
    display: inline-block;
    height: 20px;
    margin-left: 30px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin-bottom: 10px;
  }

  .logoutText {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 0px;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
    vertical-align: middle;
    margin-bottom: 8px;
  }
  /*///////////////////////////////////////*/
}













.menuHome {
  height: 20px;
  cursor: pointer;
  margin-left: 80px;
}

.menuLanguage {
  margin-top: 3px;
  height: 20px;
}







.menuToLarge {
  position: fixed;
  left: 80px;
  top: calc(50vh - 20px);
  width: 16px;
  cursor: pointer;
  z-index: 101;
}

.menuToSmall {
  position: fixed;
  left: 208px;
  top: calc(50vh - 20px);
  width: 16px;
  cursor: pointer;
  z-index: 10000000;
}





.menuLanguageContainer {
  width: 100px !important;
  margin-right: 80px !important;
}

.languageSelect {
  width: 120px;
}



.sideSVGContainerShort {
  height: 68px;
  cursor: pointer;
}

.sideSVGContainerLong {
  height: 78px;
  cursor: pointer;
}

.sideSVGContainerAnswerReview {
  height: 82px;
  cursor: pointer;
}

.sideSVGContainerLearningVideo {
  height: 74px;
  cursor: pointer;
}

.sideMenuSVG {
  width: 26px;
}

.sideMenuDashboardText {
  font-family: 'Poppins';
  font-size: 11px;
  font-weight: 300;
  color: #BF2828;
  margin-top: 0px;
  line-height: 14px;
}

.sideMenuDashboardTextLong {
  font-family: 'Poppins';
  font-size: 11px;
  font-weight: 300;
  color: #BF2828;
  margin-top: 12px;
  margin-left: 10px;
}

.sideMenuDashboardBar {
  height: 40px;
  width: 4px;
  background-color: #BF2828;
  position: absolute;
  left: 76px;
}



.sideMenuResultText {
  font-family: 'Poppins';
  font-size: 11px;
  font-weight: 300;
  color: #FFA41C;
  margin-top: 0px;
  line-height: 14px;
}

.sideMenuResultTextLong {
  font-family: 'Poppins';
  font-size: 11px;
  font-weight: 300;
  color: #FFA41C;
  margin-top: 0px;
  margin-top: 12px;
  margin-left: 10px;
}

.sideMenuResultBar {
  height: 40px;
  width: 4px;
  background-color: #FFA41C;
  position: absolute;
  left: 76px;
}



.sideMenuAnswerReviewText {
  font-family: 'Poppins';
  font-size: 11px;
  font-weight: 300;
  color: #248914;
  margin-top: 0px;
  line-height: 14px;
}

.sideMenuAnswerReviewTextLong {
  font-family: 'Poppins';
  font-size: 11px;
  font-weight: 300;
  color: #248914;
  margin-top: 12px;
  margin-left: 10px;
}

.sideMenuAsnwerResultBar {
  height: 58px;
  width: 4px;
  background-color: #248914;
  position: absolute;
  left: 76px;
}



.sideMenuLearningVideoText {
  font-family: 'Poppins';
  font-size: 11px;
  font-weight: 300;
  color: #2889BF;
  margin-top: 0px;
  line-height: 14px;
}

.sideMenuLearningVideoTextLong {
  font-family: 'Poppins';
  font-size: 11px;
  font-weight: 300;
  color: #2889BF;
  margin-top: 12px;
  margin-left: 10px;
}

.sideMenuLearningVideoBar {
  height: 46px;
  width: 4px;
  background-color: #2889BF;
  position: absolute;
  left: 76px;
}


.sideMenuExerciseText {
  font-family: 'Poppins';
  font-size: 11px;
  font-weight: 300;
  color: #BC28BF;
  margin-top: 0px;
  line-height: 14px;
}

.sideMenuExerciseTextLong {
  font-family: 'Poppins';
  font-size: 11px;
  font-weight: 300;
  color: #BC28BF;
  margin-top: 12px;
  margin-left: 10px;
}

.sideMenuExerciseBar {
  height: 40px;
  width: 4px;
  background-color: #BC28BF;
  position: absolute;
  left: 76px;
}



.sideMenuSettingText {
  font-family: 'Poppins';
  font-size: 11px;
  font-weight: 300;
  color: #4F4F4F;
  margin-top: 0px;
  line-height: 14px;
}

.sideMenuSettingTextLong {
  font-family: 'Poppins';
  font-size: 11px;
  font-weight: 300;
  color: #4F4F4F;
  margin-top: 12px;
  margin-left: 10px;
}

.sideMenuAccountSettingBar {
  height: 54px;
  width: 4px;
  background-color: #4F4F4F;
  position: absolute;
  left: 76px;
}



.sideMenuContactUsText {
  font-family: 'Poppins';
  font-size: 11px;
  font-weight: 300;
  color: #202C9E;
  margin-top: 0px;
  line-height: 14px;
}

.sideMenuContactUsTextLong {
  font-family: 'Poppins';
  font-size: 11px;
  font-weight: 300;
  color: #202C9E;
  margin-top: 12px;
  margin-left: 10px;
}

.sideMenuContactUsBar {
  height: 54px;
  width: 4px;
  background-color: #202C9E;
  position: absolute;
  left: 76px;
}








.sideMenuBox {
  flex-direction: row;
  display: flex;
  padding-left: 12px;
  margin-left: -12px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.sideMenuTitleContainer {
  position: absolute;
  top: 0px;
  width: 150px;
  text-align: center;
}

.sideMenuTitle {
  font-family: 'Poppins';
  font-weight: 700;
  color: white;
  font-size: 24px;
}

