@media (max-width: 600px){
  .conversationBoxName{
    width: 90vw;
    position: absolute;
    right: 2vw;
    bottom: 1vw;
  }
  .conversationBoxContainer{
    /*width: 88vw;*/
    position:absolute;
    right: 5vw;
    bottom: 15vw;
    height: 17vw;
    margin-left: 2vw;
  }

  .conversationBoxContainer4{
    /*width: 88vw;*/
    position:absolute;
    right: 0;
    bottom: 22vw;
    height: 17vw;
    margin-left: 2vw;


    width: 80vw;
    /*position:absolute;*/
    /*right: 0;*/
    /*bottom: 20vw;*/
    /*height: 17vw;*/
  }

  .conversationBoxContainer5{
    width: 70vw;
    position:absolute;
    right: 0vw;
    bottom: 15vw;
    height: 17vw;
    /*margin-left: 2vw;*/
  }

  .welcomeMyNameText{
    font-family: 'Nunito';
    font-weight: 700;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 1vw;
    margin-left: 1vw;
  }
  .welcomeNamePen{
    width: 3vw;
    margin-right: 0.5vw;
  }



  .conversationBoxContainer2{
    width: 75vw;
    position:absolute;
    right: 0;
    bottom: 20vw;
    height: 17vw;
  }
  .conversationBox{
    width: 75vw;
    position: absolute;
    right: 0;
    bottom: 0;
  }


  .conversationBox3{
    width: 80vw;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .welcome1Text1{
    font-family: 'Inter';
    font-weight: 300;
    color: #B4B4B4;
    font-size: 4vw;
    margin-top: 0;
    margin-bottom: 0.5vw;
  }
  .conversationOptionContainer{
    width: 63vw;
    padding: 1vw;
    background-color: #fafafa;
    margin-bottom: 0.3vw;
    border-radius:0.6vw;
    cursor: pointer;
  }
  .welcome1Text2{
    font-family: 'Inter';
    font-weight: 700;
    color: black;
    font-size: 4vw;
  }

  .welcomeBackgroundImage3{
    background-image: url("../../assets/welcome/welcomeBanner3_m.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    cursor: pointer;
    background-position: center;
  }

  .welcomeContinueText{
    color: #FC8D62;
    font-family: 'Nunito';
    font-weight: 700;
    position: absolute;
    bottom: 5vw;
    right: 5vw;
    font-size: 5vw;
    margin-top: 0px;
    margin-bottom: 0px;
  }


  .welcomeBackgroundImage6{
    background-image: url("../../assets/welcome/welcomeBanner3_m.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    cursor: pointer;
    background-position: center;
  }
}

@media (min-width: 600px){
  .conversationBoxName{
    width: 28vw;
    position: absolute;
    right: 0;
    bottom: 1vw;
  }
  .conversationBoxContainer{
    width: 26vw;
    position:absolute;
    right: 0;
    bottom: 4.25vw;
    height: 8.5vw;
  }  .conversationBoxContainer4{
       width: 26vw;
       position:absolute;
       right: 0;
       bottom: 4.25vw;
       height: 8.5vw;
     }
  .conversationBoxContainer5{
    width: 26vw;
    position:absolute;
    right: 0;
    bottom: 4.25vw;
    height: 8.5vw;
  }
  .welcomeMyNameText{
    font-family: 'Nunito';
    font-weight: 700;
    font-size: 1.6vw;
    margin-top: 0;
    margin-bottom: 1vw;
    margin-left: 1vw;
  }
  .welcomeNamePen{
    width: 1vw;
    margin-right: 0.5vw;
  }

  .conversationBoxContainer2{
    width: 26vw;
    position:absolute;
    right: 0;
    bottom: 4.25vw;
    height: 8.5vw;
  }
  .conversationBox{
    width: 28vw;
    position: absolute;
    right: 0;
    bottom: 0;
  }  .conversationBox3{
    width: 28vw;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .welcome1Text1{
    font-family: 'Inter';
    font-weight: 300;
    color: #B4B4B4;
    font-size: 1vw;
    margin-top: 0;
    margin-bottom: 0.5vw;
  }
  .conversationOptionContainer{
    width: 21vw;
    padding: 1vw;
    background-color: #fafafa;
    margin-bottom: 0.3vw;
    border-radius:0.6vw;
    cursor: pointer;
  }
  .welcome1Text2{
    font-family: 'Inter';
    font-weight: 700;
    color: black;
    font-size: 1vw;
  }
  .welcomeBackgroundImage3{
    background-image: url("../../assets/welcome/welcomeBanner3.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    cursor: pointer;
    background-position: center;
  }
  .welcomeContinueText{
    color: #FC8D62;
    font-family: 'Nunito';
    font-weight: 700;
    position: absolute;
    bottom: 2vw;
    right: 2vw;
    font-size: 1.6vw;
    margin-top: 0px;
    margin-bottom: 0px;
  }




  .welcomeBackgroundImage6{
    background-image: url("../../assets/welcome/welcomeBanner6.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    cursor: pointer;
    background-position: center;
  }


}
.p4{
  /*backgroundSize: 'contain' */
  /*backgroundRepeat: 'no-repeat'*/
  /*background-image: url("../../assets/welcome/p4.png");*/
  /*background-size: 100%;*/
  /*width: 21vw;*/
  /*z-index:1000000;*/
  /*padding: 1vw;*/
  /*background-color: #fafafa;*/
  /*margin-bottom: 0.3vw;*/
  /*border-radius:0.6vw;*/
  /*cursor: pointer;*/
}

.welcomeBackgroundImage1{
  background-image: url("../../assets/welcome/welcomeBanner1.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  background-position: center;
}
.welcomeBackgroundImage2{
  background-image: url("../../assets/welcome/welcomeBanner2.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  background-position: center;
}

.welcomeBackgroundImage4{
  background-image: url("../../assets/welcome/welcomeBanner4.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  background-position: center;
}
.welcomeBackgroundImage5{
  background-image: url("../../assets/welcome/welcomeBanner5.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  cursor: pointer;
  background-position: center;
}

.welcomeBackgroundImage7{
  background-image: url("../../assets/welcome/welcomeBanner7.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  cursor: pointer;
  background-position: center;
}
.welcomeBackgroundImage8{
  background-image: url("../../assets/welcome/welcomeBanner8.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  cursor: pointer;
  background-position: center;
}


.happy{
  position: absolute;
  right: 0;
  bottom: 2vw;
  width: 8vw;
  z-index: 2;
}
.nerd{
  position: absolute;
  right: 0;
  bottom: 12vw;
  width: 8vw;
  z-index: 2;
}



.welcome3Margin{
  height: 0.5vw;
}

.languageContainer{
  position: absolute;
  right: 20px;
  top: 20px;
}
.welcomeLanguage{
  height: 20px;
  margin-bottom: -4px;
}

.welcomeNameTextField{
  width: 80%;
  margin-left: 1vw!important;
}
.welcomeNameTextField ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-family: 'Poppins';
  font-weight: 300;
}
.welcomeNameTextField ::-moz-placeholder { /* Firefox 19+ */
  font-family: 'Poppins';
  font-weight: 300;
}
.welcomeNameTextField :-ms-input-placeholder { /* IE 10+ */
  font-family: 'Poppins';
  font-weight: 300;
}
.welcomeNameTextField :-moz-placeholder { /* Firefox 18- */
  font-family: 'Poppins';
  font-weight: 300;
}


._loading_overlay_content {
  width: 100%;
  height: 100%;
  margin: auto;
  top:50%;
  /*width: 50%;*/
  position: fixed;
}
